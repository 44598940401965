import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
// import "./CreateEvents.css";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonSearchbar,
  IonToast,
} from "@ionic/react";

import {
  alertOutline,
  call,
  checkmarkOutline,
  mail,
  refreshOutline,
  share,
} from "ionicons/icons";

// FIREBASE
import { db } from "../index";
import { collection, addDoc } from "firebase/firestore";

// HELPER
import _ from "underscore";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EventInterface } from "../pages/Admin";

interface FinanceComponentProps {
  loggedIn: boolean;
  allSoldTickets: Ticket[];
  allEvents: EventInterface[];
}

export type Ticket = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  pax: number;
  boys: number;
  girls: number;
  seller: string;
  timestamp: number;
  uid: string;
  eventId: string;
  ticketId: string;
  paid: boolean;
  checkin: boolean;
  eventName: string;
  commission: number;
  location: string;
  eventTime: string;
  eventDate: string;
  price: number;
};

const FinanceComponent: React.FC<FinanceComponentProps> = ({
  loggedIn,
  allSoldTickets,
  allEvents,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [soldOverview, setSoldOverview] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);
  const [allSoldTicketsFromSellers, setAllSoldTicketsFromSeller] = useState<
    Ticket[]
  >([]);

  const [
    filteredAllSoldTicketsFromSellers,
    setFilteredallSoldTicketsFromSeller,
  ] = useState<Ticket[]>([]);

  const [items, setItems] = useState<Ticket[]>([]);

  const errorRef = useRef("");
  const successRef = useRef("");

  const counterRef = useRef(0);

  useEffect(() => {
    if (loggedIn) {
      if (allSoldTickets.length > 0) {
        // console.log(
        //   new Date(Date.now()).getFullYear() +
        //     "-0" +
        //     (new Date(Date.now()).getMonth() + 1) +
        //     "-" +
        //     new Date(Date.now()).getDate()
        // );
        // // generateItems(allSoldTickets, false);
        // onFilterData(
        //   new Date(Date.now()).getFullYear() +
        //     "-0" +
        //     (new Date(Date.now()).getMonth() + 1) +
        //     "-" +
        //     new Date(Date.now()).getDate()
        // );

        setAllSoldTicketsFromSeller(allSoldTickets);
        calculateStatistics(allSoldTickets);
        generateItems(allSoldTickets, true);
      }
    } else {
      setFilteredallSoldTicketsFromSeller([]);
      setSoldOverview([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }, [allSoldTickets]);

  async function handleSearch(ev: Event) {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    if (query) {
      const filteredTickets = allSoldTicketsFromSellers.filter(
        (t) =>
          t.name.toLowerCase().indexOf(query) > -1 ||
          t.surname.toLowerCase().indexOf(query) > -1 ||
          t.eventName.toLowerCase().indexOf(query) > -1 ||
          t.email.toLowerCase().indexOf(query) > -1 ||
          t.phone.toLowerCase().indexOf(query) > -1 ||
          t.pax.toString().toLowerCase().indexOf(query) > -1 ||
          t.seller.toString().toLowerCase().indexOf(query) > -1 ||
          t.location.toString().toLowerCase().indexOf(query) > -1 ||
          t.eventTime.toString().toLowerCase().indexOf(query) > -1 ||
          t.eventDate.toString().toLowerCase().indexOf(query) > -1 ||
          t.paid.toString().toLowerCase().indexOf(query) > -1
      );

      setFilteredallSoldTicketsFromSeller(filteredTickets);
    } else {
      setFilteredallSoldTicketsFromSeller(allSoldTicketsFromSellers);
    }
  }
  const generateItems = (tickets: Ticket[], reset: boolean) => {
    if (reset) {
      counterRef.current = 0;
      calculateStatistics(allSoldTickets);
    }

    const newItems: Ticket[] = [];
    for (
      let i = counterRef.current * 25;
      i < (counterRef.current + 1) * 25;
      i++
    ) {
      if (i < tickets.length) {
        newItems.push(tickets[i]);
      }
    }

    if (newItems.length === 0) {
      displaySuccess("ALL TICKETS LOADED");
    }

    counterRef.current += 1;

    if (!reset) {
      setItems([...items, ...newItems]);
    } else {
      setItems([...newItems]);
    }
  };

  async function onFilterData(date: any) {
    const filteredTickets: Ticket[] = [];
    allSoldTickets.map((t) => {
      if (
        new Date(t.timestamp).getFullYear() +
          "-0" +
          (new Date(t.timestamp).getMonth() + 1) +
          "-" +
          new Date(t.timestamp).getDate() ===
        date.split("T")[0]
      ) {
        filteredTickets.push(t);
      }
    });

    setItems(filteredTickets);
    calculateStatistics(filteredTickets);
  }

  async function calculateStatistics(tickets: Ticket[]) {
    let [
      totalGirls,
      totalBoys,
      dailyGirls,
      dailyBoys,
      dailyCommission,
      totalCommission,
      totalAmountToPay,
      lastSevenDaysCommission,
      lastSevenDaysSoldBoys,
      lastSevenDaysSoldGirls,
    ] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (tickets.length === 0) {
      setSoldOverview([
        totalBoys,
        totalGirls,
        dailyBoys,
        dailyGirls,
        dailyCommission,
        totalCommission,
        totalAmountToPay,
        lastSevenDaysCommission,
        lastSevenDaysSoldBoys,
        lastSevenDaysSoldGirls,
      ]);
    }

    for (let i = 0; i < tickets.length; i++) {
      const event = allEvents.filter(
        (e: EventInterface) => e.eventId === tickets[i].eventId
      );
      const soldToday =
        new Date(tickets[i].timestamp).getDate() +
          "/" +
          new Date(tickets[i].timestamp).getMonth() ===
        new Date(Date.now()).getDate() + "/" + new Date(Date.now()).getMonth();
      const eventCommission = event[0].commission;
      totalCommission += +(eventCommission * tickets[i].pax);
      totalGirls += tickets[i].girls;
      totalBoys += tickets[i].boys;
      if (soldToday) {
        dailyCommission += +(eventCommission * tickets[i].pax);
        dailyGirls += tickets[i].girls;
        dailyBoys += tickets[i].boys;
      }
      if (!tickets[i].paid) {
        const eventPrice = event[0].price;
        totalAmountToPay += +(eventPrice * tickets[i].pax);
      }
      const timestampBeforeSevenDays = Date.now() - 7 * 24 * 60 * 60 * 1000;
      if (tickets[i].timestamp >= timestampBeforeSevenDays) {
        lastSevenDaysCommission += +(eventCommission * tickets[i].pax);
        lastSevenDaysSoldBoys += tickets[i].boys;
        lastSevenDaysSoldGirls += tickets[i].girls;
      }

      if (i === tickets.length - 1) {
        setSoldOverview([
          totalBoys,
          totalGirls,
          dailyBoys,
          dailyGirls,
          dailyCommission,
          totalCommission,
          totalAmountToPay,
          lastSevenDaysCommission,
          lastSevenDaysSoldBoys,
          lastSevenDaysSoldGirls,
        ]);
      }
    }
  }

  async function resendTicket(ticket: Ticket) {
    if (ticket.eventId && ticket.ticketId) {
      const ticketUrl = `https://aycp.eu/customer/eventId=${ticket.eventId}&ticketId=${ticket.ticketId}`;

      await addDoc(collection(db, "mail"), {
        to: [ticket.email],
        message: {
          subject: "AYCP-Ticket",
          text: "This is the plaintext section of the email body.",
          html: `This is your Ticket  <code> <br/> <br/>      <p style="text-align: center">
            <a
              style="background: #00dbfb; color: white; padding: 1%; "
              href="${ticketUrl}"
              target="_blank"
              >Get your ticket</a
            >
          </p><br/> <br/> </code> `,
        },
      });
    } else {
      displayError({ message: "Something is wrong with this ticket" });
    }
  }

  async function displayError(error: { message: string }) {
    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function displaySuccess(successMessage: string) {
    successRef.current = successMessage;
    setIsOpenSuccess(true);
  }

  return (
    <div>
      <IonGrid className="ion-no-padding">
        <IonList>
          <IonRow className="ion-no-padding">
            <IonCol sizeSm="2" size="6" offsetSm="4">
              <IonCard
                color="primary"
                className="ion-no-padding ion-text-center"
              >
                <IonCardHeader>
                  <IonCardTitle style={{ fontSize: "16px" }}>
                    <b>COMMISION</b>
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonLabel key="allCommission" style={{ fontSize: "12px" }}>
                    <b>ALL : {soldOverview[5]} €</b>
                  </IonLabel>
                  <br />
                  <IonLabel key="todayCommission" style={{ fontSize: "12px" }}>
                    <b>TODAY : {soldOverview[4]} €</b>
                  </IonLabel>
                  <br />
                  <IonLabel key="todaySold" style={{ fontSize: "12px" }}>
                    <b>LAST 7 DAYS: {soldOverview[7]} € </b>
                  </IonLabel>
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol sizeSm="2" size="6">
              <IonCard
                color="primary"
                className="ion-no-padding ion-text-center"
              >
                <IonCardHeader>
                  <IonCardTitle style={{ fontSize: "16px" }}>
                    <b>SOLD TICKETS</b>
                  </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonLabel key="sold" style={{ fontSize: "12px" }}>
                    <b>
                      ALL: {soldOverview[0] + soldOverview[1]}/{soldOverview[1]}
                    </b>
                  </IonLabel>
                  <br />
                  <IonLabel key="todaySold" style={{ fontSize: "12px" }}>
                    <b>
                      TODAY: {soldOverview[2] + soldOverview[3]}/
                      {soldOverview[3]}
                    </b>
                  </IonLabel>

                  <br />
                  <IonLabel key="lastSevenDaySold" style={{ fontSize: "12px" }}>
                    <b>
                      LAST 7 DAYS: {soldOverview[8] + soldOverview[9]}/
                      {soldOverview[9]}
                    </b>
                  </IonLabel>
                </IonCardContent>
              </IonCard>{" "}
            </IonCol>
          </IonRow>

          <div className="ion-padding">
            <IonRow>
              <IonCol offset="1" size="7" sizeSm="2" offsetSm="5">
                <IonDatetimeButton
                  datetime="date"
                  className="ion-padding"
                ></IonDatetimeButton>

                <IonModal keepContentsMounted={true}>
                  <IonDatetime
                    presentation="date"
                    id="date"
                    onIonChange={(e) => onFilterData(e.detail.value)}
                    showDefaultButtons={true}
                  ></IonDatetime>
                </IonModal>
              </IonCol>
              <IonCol>
                <IonButton
                  onClick={() => generateItems(allSoldTickets, true)}
                  fill="clear"
                  className="ion-no-padding"
                >
                  <IonIcon icon={refreshOutline} slot="icon-only"></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          </div>

          <IonSearchbar
            className="ion-no-padding"
            color="dark"
            key="searchbar"
            animated={true}
            placeholder="search"
            debounce={500}
            onIonInput={(ev) => handleSearch(ev)}
          ></IonSearchbar>
        </IonList>

        <IonList>
          {items.length > 0 &&
            items.map((ticket) => {
              return (
                <IonRow
                  key={ticket.ticketId}
                  onClick={() =>
                    displaySuccess(
                      `${ticket.eventName} ${ticket.eventDate
                        .split("-")
                        .reverse()
                        .join(".")}  ${ticket.eventTime} ${ticket.location} ${
                        ticket.email
                      }`
                    )
                  }
                >
                  <IonCol size="4">
                    <IonItem
                      color={ticket.paid ? "success" : "danger"}
                      lines="none"
                    >
                      <IonLabel>{ticket.eventName}</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="3">
                    <IonItem
                      color={ticket.paid ? "success" : "danger"}
                      lines="none"
                    >
                      <IonLabel>{ticket.name}</IonLabel>
                    </IonItem>
                  </IonCol>
                  <IonCol size="2">
                    <IonItem
                      color={ticket.paid ? "success" : "danger"}
                      lines="none"
                    >
                      <IonLabel>
                        {ticket.pax}/{ticket.girls}{" "}
                      </IonLabel>
                    </IonItem>
                  </IonCol>

                  {/* <IonCol size="1.5">
                  <IonItem
                    color={ticket.paid ? "success" : "danger"}
                    lines="none"
                  >
                    <IonLabel>
                      {ticket.eventDate &&
                        ticket.eventDate.split("-").reverse().join(".")}{" "}
                    </IonLabel>
                  </IonItem>
                </IonCol> */}
                  <IonCol size="3">
                    <IonItem
                      color={ticket.paid ? "success" : "danger"}
                      lines="none"
                    >
                      <IonIcon
                        style={{ paddingRight: "5px" }}
                        icon={mail}
                        size="small"
                        color="light"
                        onClick={() => resendTicket(ticket)}
                      ></IonIcon>
                      <CopyToClipboard
                        key={"clipboard"}
                        text={ticket.phone}
                        onCopy={() => displaySuccess("Phone number copied")}
                      >
                        <IonIcon
                          icon={call}
                          color="light"
                          size="small"
                          style={{ paddingRight: "5px" }}
                        ></IonIcon>
                      </CopyToClipboard>
                      <CopyToClipboard
                        key={"ticketUrl"}
                        text={`https://aycp.eu/customer/eventId=${ticket.eventId}&ticketId=${ticket.ticketId}`}
                        onCopy={() => displaySuccess("TICKET URL COPIED")}
                      >
                        <IonIcon
                          icon={share}
                          color="light"
                          size="small"
                        ></IonIcon>
                      </CopyToClipboard>
                    </IonItem>
                  </IonCol>
                </IonRow>

                // <IonRow key={ticket.ticketId}>
                //   <IonCol>
                //     <IonItem
                //       key={ticket.ticketId + ticket.eventId}
                //       className="ion-text-center"
                //       color={ticket.paid ? "success" : "danger"}
                //     >
                //       <IonLabel style={{ fontWeight: "bold" }}>
                //         {ticket.eventName} {ticket.surname} {ticket.name}{" "}
                //         {ticket.pax}/{ticket.girls}{" "}
                //         {ticket.commission * ticket.pax}€
                //       </IonLabel>
                //       <IonButton
                //         fill="clear"
                //         onClick={() => resendTicket(ticket)}
                //       >
                //         <IonIcon
                //           slot="icon-only"
                //           icon={mail}
                //           color="dark"
                //         ></IonIcon>
                //       </IonButton>
                //       <CopyToClipboard
                //         key={"clipboard"}
                //         text={ticket.phone}
                //         onCopy={() => displaySuccess("Phone number copied")}
                //       >
                //         <IonIcon slot="end" icon={call} color="dark"></IonIcon>
                //       </CopyToClipboard>
                //     </IonItem>
                //   </IonCol>
                // </IonRow>
              );
            })}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(allSoldTickets, false);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonGrid>
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpen}
        message={"ERROR: " + errorRef.current}
        icon={alertOutline}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              setIsOpen(false);
            },
          },
        ]}
      ></IonToast>
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpenSucess}
        message={successRef.current}
        duration={5000}
        icon={checkmarkOutline}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              setIsOpenSuccess(false);
            },
          },
        ]}
      ></IonToast>
    </div>
  );
};
export default FinanceComponent;
