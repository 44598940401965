import React from "react";
import { IonContent, IonPage } from "@ionic/react";
// import "./Auth.css";
import Header from "../components/Header";
import CustomerComponent from "../components/CustomerComponent";

interface CustomerProps {
  loggedIn: boolean;
}

const Customer: React.FC<CustomerProps> = ({ loggedIn }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header pageTtitle="AYCP-Ticket"></Header>
        <CustomerComponent loggedIn={loggedIn} />
      </IonContent>
    </IonPage>
  );
};

export default Customer;
