import React, { RefObject, useRef, useState } from "react";
import "./ExploreContainer.css";

import { auth, db } from "../index";
import {
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";

import { useForm, SubmitHandler } from "react-hook-form";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToast,
} from "@ionic/react";
import { alertOutline, closeCircle, save } from "ionicons/icons";
import { doc, updateDoc } from "firebase/firestore";

interface AuthComponentProps {
  loggedIn: boolean;
}

type Inputs = {
  // displayName: string;
  email: string;
  password: string;
};

const AuthComponent: React.FC<AuthComponentProps> = ({ loggedIn }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef() as RefObject<HTMLIonInputElement>;

  // const [user, setUser] = useState<User | null>(null);

  const [changePW, setChangePW] = useState(false);

  const errorRef = useRef("");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => login(data);

  async function login(data: Inputs) {
    console.log("LOGIN", data);

    if (data.email && data.password) {
      signInWithEmailAndPassword(auth, data.email, data.password)
        .then((user) => {
          console.log(user);
          // setLoggedIn(true);
        })
        .catch((error) => {
          console.log(111111111111);
          displayError(error);
        });
    } else {
      displayError({ message: "Please enter your email and password!" });
    }
  }

  async function onChangePW() {
    const newPW = inputRef.current?.value;

    console.log(newPW);

    const user = auth.currentUser;
    if (newPW && user) {
      updatePassword(user, newPW.toString())
        .then(async () => {
          // Update successful.

          console.log(user.uid);

          let userId = user.uid;

          if (userId === "BCuC7MsGR5RgbTjxnBe3d2VJizH3") {
            userId = "prNZpdWOOa5IUrQxDk6i";
          }

          await updateDoc(doc(db, "seller", userId), {
            password: newPW,
          });

          await updateDoc(doc(db, "allSeller", userId), {
            password: newPW,
          });

          setChangePW(false);
        })
        .catch((error) => {
          console.log(error);
          // An error ocurred
          // ...
        });
    }
  }

  async function logout() {
    signOut(auth).catch((error) => {
      displayError(error);
    });
  }

  async function displayError(error: { message: string }) {
    errorRef.current = error.message;
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, 15000);
  }
  return (
    <div>
      <IonGrid>
        <div className="container">
          <IonRow>
            <IonCol sizeSm="4" offsetSm="4">
              <IonImg
                src="/assets/icon/icon.png"
                style={{
                  width: "50%",
                  heght: "50%",
                  marginLeft: "25%",
                }}
              ></IonImg>
            </IonCol>
          </IonRow>
          {!loggedIn && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonList>
                {/* <IonItem lines="full"> */}
                {/* <IonInput
                {...register("displayName", { required: true })}
                label="Username"
                type="text"
                placeholder="Please enter the username"
                labelPlacement="floating"
              ></IonInput>
            </IonItem>
            {errors.displayName && <span>This field is required</span>} */}

                <IonRow>
                  <IonCol sizeSm="6" offsetSm="3">
                    <IonItem lines="full">
                      <IonInput
                        {...register("email", { required: true })}
                        label="E-Mail"
                        type="email"
                        placeholder="Please enter your E-Mail"
                        labelPlacement="floating"
                      ></IonInput>
                    </IonItem>
                    {errors.email && <span>This field is required</span>}

                    <IonItem lines="full">
                      <IonInput
                        {...register("password", { required: true })}
                        label="Password"
                        type="password"
                        placeholder="Please enter your password"
                        labelPlacement="floating"
                      ></IonInput>
                    </IonItem>
                    {errors.password && (
                      <span>
                        This field is required {errors.password.message}
                      </span>
                    )}

                    <IonGrid>
                      <IonRow>
                        <IonCol size="8" offset="2" sizeSm="4" offsetSm="4">
                          <IonButton
                            className="ion-padding ion-margin"
                            color="dark"
                            type="submit"
                            expand="block"
                            size="large"
                            // disabled={!isValid}
                          >
                            LOGIN
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              </IonList>
            </form>
          )}
          {loggedIn && (
            <>
              <div>
                <strong>
                  HELLO {auth.currentUser?.displayName?.toUpperCase()}
                </strong>

                <IonGrid>
                  <IonRow>
                    <IonCol size="8" offset="2" sizeSm="2" offsetSm="5">
                      <IonButton
                        className="ion-padding ion-margin"
                        color="dark"
                        expand="block"
                        size="large"
                        onClick={logout}
                      >
                        LOGOUT
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
              <IonCol size="2" sizeSm="2">
                {!changePW && (
                  <IonLabel onClick={() => setChangePW(true)}>
                    CHANGE PASSWORD
                  </IonLabel>
                )}

                {changePW && (
                  <>
                    <IonRow className="ion-text-center">
                      <IonCol sizeSm="2" offsetSm="5">
                        <IonItem className="ion-text-center" lines="none">
                          <IonLabel
                            position="floating"
                            className="ion-text-center"
                          >
                            ENTER NEW PASSWORD{" "}
                          </IonLabel>

                          <IonInput
                            placeholder="TYPE HERE"
                            type="text"
                            ref={inputRef}
                            onClick={() => setChangePW(true)}
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                      {/* <IonCol>
                        {" "}
                        <IonButton fill="clear" onClick={() => onChangePW()}>
                          <IonIcon icon={save}></IonIcon>
                        </IonButton>
                      </IonCol> */}
                    </IonRow>
                    <IonRow className="ion-text-center">
                      <IonCol size="6" offset="2" offsetSm="5.5" sizeSm="1">
                        <IonButton onClick={() => onChangePW()}>
                          CHANGE PASSWORD
                        </IonButton>
                      </IonCol>{" "}
                      <IonCol size="2" sizeSm="0.5">
                        <IonButton
                          onClick={() => setChangePW(false)}
                          fill="clear"
                        >
                          <IonIcon
                            icon={closeCircle}
                            color="danger"
                            slot="icon-only"
                          ></IonIcon>{" "}
                        </IonButton>
                      </IonCol>
                      {/* <IonCol>
                        {" "}
                        <IonButton fill="clear" onClick={() => onChangePW()}>
                          <IonIcon icon={save}></IonIcon>
                        </IonButton>
                      </IonCol> */}
                    </IonRow>
                  </>
                )}
              </IonCol>
            </>
          )}
          <IonToast
            style={{ textAlign: "center" }}
            isOpen={isOpen}
            message={"ERROR: " + errorRef.current}
            duration={5000}
            icon={alertOutline}
          ></IonToast>
        </div>
      </IonGrid>
    </div>
  );
};

export default AuthComponent;
