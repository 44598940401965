import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  ticket,
  logIn,
  cash,
  speedometer,
  logOut,
  qrCode,
} from "ionicons/icons";
import Auth from "./pages/Auth";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth } from "./index";
import Admin from "./pages/Admin";
import Ticket from "./pages/Ticket";
import Finance from "./pages/Finance";
import QRScanner from "./pages/QRScanner";
import Customer from "./pages/Customer";
setupIonicReact();

const App: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    window.location.href.split("/").slice(-1)[0]
  );

  const [user, setUser] = useState<User | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
        setUser(user);
      } else {
        setLoggedIn(false);
        setUser(user);
      }
    });
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs onIonTabsWillChange={(tab) => setSelectedTab(tab.detail.tab)}>
          <IonRouterOutlet>
            <Route exact path="/auth">
              <Auth loggedIn={loggedIn} />
            </Route>
            <Route exact path="/customer/:id">
              <Customer loggedIn={loggedIn} />
            </Route>
            {loggedIn && (
              <Route exact path="/ticket">
                <Ticket loggedIn={loggedIn} />
              </Route>
            )}
            {loggedIn && (
              <Route path="/finance">
                <Finance loggedIn={loggedIn} />
              </Route>
            )}
            {user && user.photoURL === "admin" && (
              <Route path="/admin">
                <Admin loggedIn={loggedIn} />
              </Route>
            )}

            {user &&
              (user.photoURL === "admin" || user.photoURL === "qrSeller") && (
                <Route path="/qrscanner">
                  <QRScanner loggedIn={loggedIn} />
                </Route>
              )}
            <Route exact path="/">
              <Redirect to="/auth" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom" color="primary">
            {loggedIn &&
              (user?.photoURL === "admin" || user?.photoURL === "qrSeller") && (
                <IonTabButton tab="qrscanner" href="/qrscanner">
                  <IonIcon icon={qrCode} color="light" />
                  {selectedTab === "qrscanner" && (
                    <IonLabel
                      color="light"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        paddingBottom: "10%",
                      }}
                    >
                      QR-Scanner
                    </IonLabel>
                  )}{" "}
                </IonTabButton>
              )}

            <IonTabButton tab="auth" href="/auth">
              <IonIcon icon={loggedIn ? logOut : logIn} color="light" />
              {selectedTab === "auth" && (
                <IonLabel
                  color="light"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    paddingBottom: "10%",
                  }}
                >
                  {loggedIn ? "logout" : "Login"}
                </IonLabel>
              )}
            </IonTabButton>
            {loggedIn && user && user.photoURL !== "qrSeller" && (
              <IonTabButton tab="ticket" href="/ticket">
                <IonIcon icon={ticket} color="light" />
                {selectedTab === "ticket" && (
                  <IonLabel
                    color="light"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingBottom: "10%",
                    }}
                  >
                    Ticket
                  </IonLabel>
                )}
              </IonTabButton>
            )}
            {loggedIn && user && user.photoURL !== "qrSeller" && (
              <IonTabButton tab="finance" href="/finance">
                <IonIcon icon={cash} color="light" />
                {selectedTab === "finance" && (
                  <IonLabel
                    color="light"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingBottom: "10%",
                    }}
                  >
                    Finance
                  </IonLabel>
                )}
              </IonTabButton>
            )}

            {loggedIn && user && user.photoURL === "admin" && (
              <IonTabButton tab="admin" href="/admin">
                <IonIcon icon={speedometer} color="light" />
                {selectedTab === "admin" && (
                  <IonLabel
                    color="light"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      paddingBottom: "10%",
                    }}
                  >
                    Admin
                  </IonLabel>
                )}
              </IonTabButton>
            )}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
  // } else {
  //   return (
  //     <IonApp>
  //       <IonReactRouter>
  //         <IonTabs
  //           onIonTabsWillChange={(tab) => setSelectedTab(tab.detail.tab)}
  //         >
  //           <IonRouterOutlet>
  //             <Route exact path="/auth">
  //               <Auth loggedIn={loggedIn} />
  //             </Route>
  //             <Route exact path="/customer/:id">
  //               <Customer loggedIn={loggedIn} />
  //             </Route>
  //           </IonRouterOutlet>
  //           <IonTabBar slot="bottom" color="primary">
  //             <IonTabButton tab="auth" href="/auth">
  //               <IonIcon icon={loggedIn ? logOut : logIn} color="dark" />
  //               {selectedTab === "auth" && (
  //                 <IonLabel
  //                   color="dark"
  //                   style={{
  //                     fontSize: "16px",
  //                     fontWeight: "bold",
  //                     paddingBottom: "10%",
  //                   }}
  //                 >
  //                   {loggedIn ? "logout" : "Login"}
  //                 </IonLabel>
  //               )}
  //             </IonTabButton>
  //           </IonTabBar>
  //         </IonTabs>
  //       </IonReactRouter>
  //     </IonApp>
  //   );
  // }
};

export default App;
