import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./ExploreContainer.css";

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToast,
} from "@ionic/react";
import {
  alertOutline,
  calendar,
  logoInstagram,
  people,
  pin,
  ticketSharp,
  time,
  wine,
} from "ionicons/icons";
import { useParams } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import { DocumentData, doc, getDoc } from "firebase/firestore";
import { db } from "..";
import { TicketInterface } from "./QRScannerComponent";

interface CustomerComponentProps {
  loggedIn: boolean;
}

type Inputs = {
  // displayName: string;
  email: string;
  password: string;
};

const CustomerComponent: React.FC<CustomerComponentProps> = ({ loggedIn }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ticket, setTicket] = useState<TicketInterface>();
  const [wrongTicket, setWrongTicket] = useState<boolean>(false);
  const [qrDetails, setQrDetails] = useState<{
    eventId: string;
    ticketId: string;
  }>();
  const params: { id: string } = useParams();
  const successRef = useRef("");

  console.log(params.id);

  // const [user, setUser] = useState<User | null>(null);

  const errorRef = useRef("");

  useMemo(() => {
    const eventId = params.id.split("eventId=")[1].split("&")[0];
    const ticketId = params.id.split("ticketId=")[1];

    getDoc(doc(db, "tickets", "events", eventId, ticketId)).then(
      (ticket: DocumentData) => {
        if (ticket.exists()) {
          setTicket(ticket.data());

          setQrDetails({
            eventId,
            ticketId,
          });
        } else {
          displayError({ message: "NO VALID TICKET INFORMATION" });
          setWrongTicket(true);
        }
      }
    );

    console.log(eventId, ticketId);
  }, [params]);

  async function displayError(error: { message: string }) {
    console.log(error);
    errorRef.current = error.message;
    setIsOpen(true);
  }

  function calculateEventTime(eventTime: string, duration: number): string {
    console.log(eventTime, duration);
    const timeRes = +eventTime.split(":")[0] + duration;
    console.log(timeRes);
    let eventTimeCalculated: string;
    if (timeRes > 24) {
      eventTimeCalculated =
        0 + (timeRes - 24).toString() + ":" + eventTime.split(":")[1];
    } else {
      eventTimeCalculated = timeRes + ":" + eventTime.split(":")[1];
    }
    console.log(eventTimeCalculated);
    return eventTimeCalculated;
  }

  // async function getRouteParams() {
  //   console.log(11);
  //   const id: string[] = useParams();

  //   const eventId = id[0].split("?")[1].split("eventId=")[1].split("&");
  //   const ticketId = id[0].split("?")[1].split("ticketId=")[1];

  //   console.log(eventId, ticketId);
  //   // ?eventId=QDqwd&awf=asf
  // }
  return (
    <IonGrid>
      <div>
        <h1 className="ion-text-center">
          <b>IT`S TIME TO PARTY</b>{" "}
        </h1>
        <IonRow className="ion-no-padding">
          <IonCol
            size="4"
            offset="4"
            sizeSm="2"
            offsetSm="5"
            className="ion-no-margin"
          >
            <IonImg
              src="/assets/icon/icon.png"
              className="ion-text-center"
            ></IonImg>
          </IonCol>
        </IonRow>
        {qrDetails && (
          <>
            <div className="qrDiv ion-text-center ion-no-margin">
              <QRCode
                value={qrDetails.eventId + "__" + qrDetails.ticketId}
                size={300}
              />
            </div>
            <IonList>
              <IonRow className="ion-text-center ">
                <IonCol sizeSm="4" offsetSm="4" size="12">
                  <IonLabel
                    className="ion-text-center"
                    style={{ fontWeight: "bold", fontSize: "32px" }}
                  >
                    {ticket?.eventName}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="ion-text-center ">
                <IonCol sizeSm="4" offsetSm="4" size="12">
                  <IonLabel
                    className="ion-text-center"
                    style={{ fontWeight: "bold", fontSize: "32px" }}
                  >
                    {ticket?.surname} {ticket?.name}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="ion-text-center">
                <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                  <IonItem lines="none">
                    <IonIcon
                      icon={calendar}
                      slot="start"
                      color="primary"
                    ></IonIcon>
                    <IonLabel
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      className="ion-text-center"
                    >
                      {ticket?.eventDate.split("-").reverse().join(".")}
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>

              {ticket && (
                <IonRow className="ion-text-center">
                  <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                    <IonItem lines="none">
                      <IonIcon
                        icon={time}
                        slot="start"
                        color="primary"
                      ></IonIcon>
                      <IonLabel
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                        className="ion-text-center"
                      >
                        {ticket?.eventTime} -{" "}
                        {calculateEventTime(
                          ticket?.eventTime,
                          ticket?.duration
                        )}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}

              {/* <IonRow className="ion-text-center">
                <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                  <IonItem lines="none">
                    <IonIcon icon={time} slot="start" color="primary"></IonIcon>
                    <IonLabel
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      className="ion-text-center"
                    >
                      {ticket?.time}
                    </IonLabel>
                    <IonIcon icon={time} slot="end" color="primary"></IonIcon>
                  </IonItem>
                </IonCol>
              </IonRow> */}

              <IonRow>
                <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                  <IonItem lines="none">
                    <IonIcon
                      icon={people}
                      slot="start"
                      color="primary"
                    ></IonIcon>
                    <IonLabel
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      className="ion-text-center"
                    >
                      {ticket?.pax} Persons
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
              {ticket && ticket.drinks === "OPEN BAR" && (
                <IonRow>
                  <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                    <IonItem lines="none">
                      <IonIcon
                        icon={wine}
                        slot="start"
                        color="primary"
                      ></IonIcon>

                      <IonLabel
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                        className="ion-text-center"
                      >
                        {ticket.drinks}
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}

              {ticket && +ticket.drinks > 0 && (
                <IonRow>
                  <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                    <IonItem lines="none">
                      <IonIcon
                        icon={wine}
                        slot="start"
                        color="primary"
                      ></IonIcon>

                      <IonLabel
                        style={{ fontWeight: "bold", fontSize: "20px" }}
                        className="ion-text-center"
                      >
                        {ticket.drinks} DRINKS
                      </IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              )}
              <IonRow>
                <IonCol sizeSm="4" offsetSm="4" size="10" offset="1">
                  <IonItem lines="none">
                    <IonIcon icon={pin} slot="start" color="primary"></IonIcon>

                    <IonLabel
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                      className="ion-text-center"
                    >
                      {ticket?.location}
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="ion-text-center">
                <IonCol sizeSm="4" offsetSm="4" size="12">
                  {ticket &&
                    ticket.description.includes("_/" || "__/") &&
                    ticket.description
                      // "CLUB77_/Afrobeat and Shatta music--/Twerk contest"
                      // CLUB77_/Afrobeat and Shatta music--/Twerk contest
                      .split("_/" && "_/")[1]
                      .split("--/" && "-/")
                      .map((r) => {
                        console.log(r);
                        return (
                          // eslint-disable-next-line react/jsx-key
                          // eslint-disable-next-line react/jsx-key
                          <>
                            <IonLabel
                              key={r}
                              style={{ fontWeight: "bold", fontSize: "20px" }}
                            >
                              + {r}
                            </IonLabel>
                            <br />
                          </>
                        );
                      })}
                </IonCol>
              </IonRow>
            </IonList>
            {/* <h3> Event: {ticket?.eventName}</h3>

            <h3>Start: {ticket?.eventTime}</h3>
            {/* <h1>Duration: {ticket?.duration}</h1> */}
            {/* <h3>Drinks: {ticket?.drinks}</h3>
            <h3>Location: {ticket?.location}</h3>
            <h3>
              This can be some details information the customer needes to know
            </h3> */}

            <IonRow className="ion-text-center">
              <IonCol size="8" offset="2">
                <IonButton
                  fill="clear"
                  color="dark"
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/unite_event/",
                      "_blank"
                    )
                  }
                >
                  <IonIcon
                    icon={logoInstagram}
                    size="large"
                    slot="icon-only"
                  ></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
        {!qrDetails ||
          (wrongTicket && (
            <IonGrid>
              <IonRow>
                <IonCol size="8" offset="2" sizeSm="2" offsetSm="5">
                  <IonItem lines="none">
                    <IonLabel>Ticket loading...</IonLabel>
                    <IonSpinner></IonSpinner>
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}

        {wrongTicket && (
          <h1 className="ion-text-center">
            Something went wrong with your ticket... please contact +35699338608
          </h1>
        )}
        <IonToast
          style={{ textAlign: "center" }}
          isOpen={isOpen}
          message={"ERROR: " + errorRef.current}
          duration={5000}
          icon={alertOutline}
        ></IonToast>
      </div>
    </IonGrid>
  );
};

export default CustomerComponent;
