import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./CreateEvents.css";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  DatetimeChangeEventDetail,
  IonButton,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRange,
  IonRow,
  IonToast,
} from "@ionic/react";

import { alertOutline, checkmarkOutline } from "ionicons/icons";
import { auth, db } from "../../..";
import {
  DocumentData,
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { RangeValue } from "@ionic/core";
import { EventInterface } from "../../../pages/Admin";
import { CreatedEvent } from "../analytics/Analytics";

interface CreateSellerComponent {
  loggedIn: boolean;
  event?: Inputs | null;
  setOpenEditModal?: Dispatch<SetStateAction<boolean>>;
}

type Inputs = {
  name: string;
  active: boolean;
  description: string;
  drinks: string;
  price: number;
  commission: number;
  date: Date;
  time: string;
  duration: number;
  location: string;
  capacity: number;
  eventId: string;
};

const CreateEvents: React.FC<CreateSellerComponent> = ({
  loggedIn,
  event,
  setOpenEditModal,
}) => {
  console.log(event, "event");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);
  // const [segment, setSegment] = useState<any>("allEvents");
  // const [allEvents, setAllEvents] = useState<Inputs[]>([]);

  // const [lastEmittedValue, setLastEmittedValue] = useState<RangeValue>();

  console.log(new Date(Date.now()).toDateString());

  const errorRef = useRef("");
  const successRef = useRef("");
  const lastEmitedRef = useRef(5);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => createEvent(data);

  console.log(getValues());

  useEffect(() => {
    // getAllEvents();
    // console.log(event);

    if (event) {
      lastEmitedRef.current = event.duration;
      setDatetime(`${event.date}T${event.time}:00.00Z`);
    }

    // return unsubscribe();
  }, []);

  async function setRangeValue(rangeValue: RangeValue) {
    setValue("duration", +rangeValue);
    lastEmitedRef.current = +rangeValue;
  }

  // async function getAllEvents() {
  //   const q = query(collection(db, "events"), where("active", "==", true));
  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const events: Inputs[] = [];
  //     querySnapshot.forEach((doc: DocumentData) => {
  //       events.push({ ...doc.data(), eventId: doc.id });
  //     });
  //     setAllEvents(events);
  //     console.log(allEvents);
  //   });

  //   return unsubscribe;
  // }

  async function onReset() {
    console.log("reset");

    if (setOpenEditModal) {
      setOpenEditModal(false);
    }

    // setLastEmittedValue(0 as RangeValue);
    lastEmitedRef.current = 0;
    reset();
  }

  function capitalizeWords(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  async function createEvent(data: Inputs) {
    console.log("LOGIN", data);

    console.log(getValues().date);

    let inputs: Inputs;

    if (event) {
      inputs = data;
    } else {
      inputs = getValues();
    }

    console.log(inputs);

    const fields = [
      "capacity",
      "commission",
      "description",
      "drinks",
      "duration",
      "location",
      "name",
      "price",
      "date",
      "time",
    ];

    const filledInputs = Object.keys(inputs);

    console.log(filledInputs);

    const missingFields = fields.filter((f) => !filledInputs.includes(f));

    console.log(missingFields);

    if (event) {
      await updateDoc(doc(db, "events", event?.eventId), {
        commission: +data.commission,
        capacity: +data.capacity,
        date: data.date,
        description: data.description,
        drinks: data.drinks.toUpperCase(),
        location: capitalizeWords(data.location),
        duration: lastEmitedRef.current,
        name: capitalizeWords(data.name),
        price: +data.price,
        time: data.time,
        timestamp: Date.now(),
        active: true,
      });

      displaySuccess("Event successfully changed");

      if (setOpenEditModal) {
        setTimeout(() => {
          setOpenEditModal(false);
        }, 1500);
      }
    }

    if (
      inputs.date &&
      inputs.capacity &&
      inputs.commission &&
      inputs.description &&
      inputs.drinks &&
      inputs.duration &&
      inputs.location &&
      inputs.name &&
      inputs.price &&
      inputs.time &&
      !event
    ) {
      addDoc(collection(db, "events"), {
        commission: +data.commission,
        capacity: +data.capacity,
        date: data.date,
        description: data.description,
        drinks: data.drinks.toUpperCase(),
        location: capitalizeWords(data.location),
        duration: lastEmitedRef.current,
        name: capitalizeWords(data.name),
        price: +data.price,
        time: data.time,
        timestamp: Date.now(),
        active: true,
      })
        .then(async (createdEvent) => {
          displaySuccess("Event created successfully");

          await setDoc(doc(db, "allEvents", createdEvent.id), {
            commission: +data.commission,
            capacity: +data.capacity,
            date: data.date,
            description: data.description,
            drinks: data.drinks.toUpperCase(),
            location: capitalizeWords(data.location),
            duration: lastEmitedRef.current,
            name: capitalizeWords(data.name),
            price: +data.price,
            time: data.time,
            timestamp: Date.now(),
            active: true,
          });

          reset();
          lastEmitedRef.current = 0;
          if (setOpenEditModal) {
            setOpenEditModal(false);
          }
        })
        .catch((error) => {
          displayError(error);
        });
    } else {
      displayError({
        message:
          "Please fill out the missing fields: " + missingFields.join(", "),
      });
    }
  }

  async function displayError(error: { message: string }) {
    console.log(error);

    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function displaySuccess(successMessage: string) {
    console.log("MESSAGEE");
    successRef.current = successMessage;
    setIsOpenSuccess(true);

    setTimeout(() => {
      setIsOpenSuccess(false);
    }, 5000);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function setDatetime(datetime: any) {
    console.log(datetime.split("T")[1].split(":", -1)[0]);
    setValue("time", datetime.split("T")[1].slice(0, 5));
    setValue("date", datetime.split("T")[0]);
  }

  return (
    <div className="container" style={{ marginTop: event ? "100px" : 0 }}>
      <h2>Enter the event details</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid>
          <IonRow className="ion-padding">
            <IonCol sizeSm={event ? "12" : "4"} offsetSm={event ? "0" : "4"}>
              <IonList>
                <IonItem lines="full">
                  <IonInput
                    value={event ? event.name : getValues().name}
                    {...register("name", { required: true })}
                    label="Event name"
                    type="text"
                    placeholder="Please enter the name of the event"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.name && <span>This field is required</span>}

                <IonItem lines="full">
                  <IonInput
                    value={event ? event.description : getValues().description}
                    {...register("description", { required: true })}
                    label="Description"
                    type="text"
                    placeholder="Please enter the name of the event"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.description && <span>This field is required</span>}

                <IonItem lines="full">
                  <IonInput
                    value={event ? event.location : getValues().location}
                    {...register("location", { required: true })}
                    label="Location"
                    type="text"
                    placeholder="Please enter the location"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.location && <span>This field is required</span>}
                <IonItem lines="full">
                  <IonInput
                    value={event ? event.drinks : getValues().drinks}
                    {...register("drinks", { required: true })}
                    label="Drinks"
                    type="text"
                    placeholder="Please enter the included drinks"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.drinks && <span>This field is required</span>}

                <IonItem lines="full">
                  <IonInput
                    value={event ? event.price : getValues().price}
                    {...register("price", { required: true })}
                    label="Price"
                    type="number"
                    placeholder="Please enter the price"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.price && (
                  <span>This field is required {errors.price.message}</span>
                )}

                <IonItem lines="full">
                  <IonInput
                    value={event ? event.commission : getValues().commission}
                    {...register("commission", { required: true })}
                    label="Commission"
                    type="text"
                    placeholder="Please enter the commission"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.price && (
                  <span>
                    This field is required {errors.commission?.message}
                  </span>
                )}

                <IonItem lines="full">
                  <IonInput
                    value={event ? event.capacity : getValues().capacity}
                    {...register("capacity", { required: true })}
                    label="Capacity"
                    type="number"
                    placeholder="Please enter the capacity"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.capacity && (
                  <span>This field is required {errors.capacity.message}</span>
                )}

                <div className="ion-padding">
                  <IonDatetimeButton datetime="datetime"></IonDatetimeButton>

                  <IonModal keepContentsMounted={true}>
                    <IonDatetime
                      value={
                        event
                          ? `${event.date}T${event.time}:00.000Z`
                          : getValues().date
                          ? `${getValues().date}T${getValues().time}:00.000Z`
                          : null
                      }
                      id="datetime"
                      onIonChange={(e) => setDatetime(e.detail.value)}
                    ></IonDatetime>
                  </IonModal>
                </div>

                <div>
                  <IonRange
                    {...register("duration", { required: true })}
                    value={lastEmitedRef.current}
                    className="ion-padding"
                    onIonChange={({ detail }) => setRangeValue(detail.value)}
                    ticks={true}
                    snaps={true}
                    pin={true}
                    min={3}
                    max={8}
                    pinFormatter={(value: number) => `${value}h`}
                  ></IonRange>
                </div>
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol
              size="6"
              sizeSm={event ? "6" : "2"}
              offsetSm={event ? "0" : "4"}
            >
              <IonButton
                className="ion-padding ion-margin"
                color="dark"
                expand="block"
                size="small"
                onClick={() => {
                  onReset();
                }}
              >
                RESET
              </IonButton>
            </IonCol>
            <IonCol
              size="6"
              sizeSm={event ? "6" : "2"}
              offsetSm={event ? "0" : "0"}
            >
              <IonButton
                className="ion-padding ion-margin"
                color="dark"
                expand="block"
                type="submit"
                size="small"
                disabled={!isValid}
              >
                {event ? "UPDATE" : "CREATE"}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
      {errorRef.current && (
        <IonToast
          style={{ textAlign: "center" }}
          isOpen={isOpen}
          message={"ERROR: " + errorRef.current}
          icon={alertOutline}
          buttons={[
            {
              text: "OK",
              role: "cancel",
              handler: () => {
                setIsOpen(false);
              },
            },
          ]}
        ></IonToast>
      )}
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpenSucess}
        message={successRef.current}
        duration={5000}
        icon={checkmarkOutline}
      ></IonToast>
    </div>
  );
};

export default CreateEvents;
