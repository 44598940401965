import React, { useRef, useState } from "react";

import _ from "underscore";
import {
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonToast,
} from "@ionic/react";
import { alertOutline } from "ionicons/icons";

interface ReportsProps {
  loggedIn: boolean;
  allSoldTickets: Ticket[];
}

type Ticket = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  pax: number;
  boys: number;
  girls: number;
  seller: string;
  timestamp: number;
  uid: string;
  eventId: string;
  ticketId: string;
  paid: boolean;
  checkin: boolean;
  eventName: string;
  commission: number;
  eventDate: string;
  eventTime: string;
  price: number;
};

const Reports: React.FC<ReportsProps> = ({ loggedIn, allSoldTickets }) => {
  const [reportData, setReportData] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allEvents, setAllEvents] = useState<string[]>([]);

  const fromDateRef = useRef<any>("");
  const toDateRef = useRef<any>("");

  const widthRef = useRef<number>(window.innerWidth);

  const errorRef = useRef("");

  async function displayError(error: { message: string }) {
    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function generateReport() {
    if (!fromDateRef.current || !toDateRef.current) {
      displayError({ message: "Please select a from and a to date" });
    } else {
      const filteredTickets: Ticket[] = [];
      for (const ticket of allSoldTickets) {
        const dateFromTimestamp = new Date(fromDateRef.current).getTime();
        const dateToTimestamp = new Date(toDateRef.current).getTime();

        if (
          ticket.timestamp >= dateFromTimestamp &&
          ticket.timestamp <= dateToTimestamp
        ) {
          filteredTickets.push(ticket);
        }
      }

      const groupedSeller = _.groupBy(filteredTickets, "seller");
      const result = _.map(groupedSeller, function (value, key) {
        return {
          seller: key,
          money: _.reduce(
            value,
            function (total, o) {
              return total + o.price * o.pax;
            },
            0
          ),
          commission: _.reduce(
            value,
            function (total, o) {
              return total + o.commission * o.pax;
            },
            0
          ),
          pax: _.reduce(
            value,
            function (total, o) {
              return total + o.girls + o.boys;
            },
            0
          ),
          boys: _.reduce(
            value,
            function (total, o) {
              return total + o.boys;
            },
            0
          ),
          girls: _.reduce(
            value,
            function (total, o) {
              return total + o.girls;
            },
            0
          ),

          events: _.groupBy(
            filteredTickets.filter((e) => e.seller === key),
            "eventName"
          ),
        };
      });

      const allEvents: string[] = [];
      const allSeller: string[] = [];

      result.map((seller) => {
        for (const [key, value] of Object.entries(seller["events"])) {
          if (!allEvents.includes(key)) {
            allEvents.push(key);
          }
          if (!allSeller.includes(value[0].seller)) {
            allSeller.push(value[0].seller);
          }
        }
      });

      const groupedEventsArr: any = [];

      for (const seller of allSeller) {
        const groupedEvents = _.groupBy(
          filteredTickets.filter((t) => t.seller === seller),
          "eventName"
        );

        const eventsResult = _.map(groupedEvents, function (value, key) {
          return {
            eventName: key,
            seller: seller,
            boys: _.reduce(
              value,
              function (total, o) {
                return total + o.boys;
              },
              0
            ),
            girls: _.reduce(
              value,
              function (total, o) {
                return total + o.girls;
              },
              0
            ),
            pax: _.reduce(
              value,
              function (total, o) {
                return total + o.girls + o.boys;
              },
              0
            ),
          };
        });

        groupedEventsArr.push(eventsResult);
      }

      const finishedSellerObj: any[] = [];

      for (const res of result) {
        const seller = res.seller;
        const Obj: any = { ...res };

        const filtered = groupedEventsArr.map((f: any) =>
          f.filter((f: any) =>
            f.seller === seller ? (Obj[f.eventName] = f.boys + f.girls) : null
          )
        );

        finishedSellerObj.push(Obj);
      }

      if (finishedSellerObj.length > 0) {
        const keys: string[] = [];

        for (const data of finishedSellerObj) {
          const allKeys = Object.keys(data);

          for (const key of allKeys) {
            if (!keys.includes(key)) {
              keys.push(key);
            }
          }
        }

        const re: any = finishedSellerObj.map((f) => _.omit(f, "events"));

        setAllEvents(keys.filter((f) => f !== "events"));

        for (const event of allEvents) {
          for (const seller of re) {
            if (!Object.prototype.hasOwnProperty.call(seller, event)) {
              seller[event] = 0;
            }
          }
        }

        setReportData(_.sortBy(re, "pax").reverse());
      }
    }
  }

  async function exportData() {
    const refinedData: any = [];
    // refinedData.push(allEvents.sort());

    reportData.sort().forEach((item: any, index: number) => {
      const sortObject = Object.keys(item)
        .sort()
        .reverse()
        .reduce((res: any, key) => ((res[key] = item[key]), res), {});
      if (index === 0) {
        refinedData.push(Object.keys(sortObject));
      }
      refinedData.push(Object.values(sortObject));
    });
    console.log(refinedData);

    let csvContent = "";

    refinedData.forEach((row: any) => {
      csvContent += row.join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", objUrl);
    link.setAttribute("download", "File.csv");
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }

  async function resetReport() {
    setReportData(null);
    fromDateRef.current = "";
    toDateRef.current = "";
  }

  return widthRef.current > 1000 ? (
    <>
      <div className="ion-text-center">
        {!reportData && (
          <IonGrid>
            <IonRow className="ion-no-padding">
              <IonCol size="12" sizeSm="4" offsetSm="2">
                <IonDatetime
                  presentation="date-time"
                  preferWheel={true}
                  style={{ margin: "auto" }}
                  onIonChange={(e) =>
                    (fromDateRef.current = e.detail.value
                      ?.toString()
                      .split("T"))
                  }
                >
                  <span slot="title">Select from date</span>
                </IonDatetime>
              </IonCol>
              <IonCol size="12" sizeSm="4" offsetSm="1">
                <IonDatetime
                  style={{ margin: "auto" }}
                  presentation="date-time"
                  preferWheel={true}
                  onIonChange={(e) =>
                    (toDateRef.current = e.detail.value?.toString().split("T"))
                  }
                >
                  {" "}
                  <span slot="title">Select to date</span>
                </IonDatetime>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeSm="4" offsetSm="4">
                <IonButton
                  className="ion-padding ion-margin"
                  color="dark"
                  expand="block"
                  size="small"
                  onClick={() => {
                    generateReport();
                  }}
                >
                  GENERATE REPORT
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        {reportData && (
          <>
            <IonRow className="ion-text-center">
              <IonCol sizeSm="2" offsetSm="4">
                <IonButton
                  className="ion-padding ion-margin"
                  color="dark"
                  expand="block"
                  size="small"
                  onClick={() => {
                    resetReport();
                  }}
                >
                  New Report
                </IonButton>
              </IonCol>
              <IonCol size="2" sizeSm="2">
                <IonButton
                  className="ion-padding ion-margin"
                  color="dark"
                  expand="block"
                  size="small"
                  onClick={() => {
                    exportData();
                  }}
                >
                  EXPORT DATA
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow></IonRow>

            <IonRow>
              {Object.entries(reportData[0])
                .reverse()
                .map(([key, value]) => {
                  return (
                    <IonCol key={key} size={(12 / allEvents.length).toString()}>
                      <IonItem lines="full">
                        <IonLabel
                          style={{ fontWeight: "bold", fontSize: "20px" }}
                          className="ion-text-center"
                        >
                          <b>{key.toUpperCase()}</b>
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                  );
                })}
            </IonRow>
            <IonRow className="ion-text-center">
              {reportData.map((seller: any) => {
                return Object.entries(seller)
                  .reverse()
                  .map(([key, value]) => {
                    return (
                      <>
                        <IonCol size={(12 / allEvents.length).toString()}>
                          <IonItem lines="full" className="ion-text-center">
                            <IonLabel>{seller[key]}</IonLabel>
                          </IonItem>
                        </IonCol>
                      </>
                    );
                  });
              })}
            </IonRow>
          </>
        )}
      </div>
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpen}
        message={"ERROR: " + errorRef.current}
        icon={alertOutline}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              setIsOpen(false);
            },
          },
        ]}
      ></IonToast>
    </>
  ) : (
    <div className="container">
      <h1> Please use a Laptop or PC</h1>
    </div>
  );
};

export default Reports;
