import React, { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import {
  accessibility,
  alertOutline,
  arrowForward,
  calendarOutline,
  call,
  cash,
  checkmarkOutline,
  close,
  female,
  help,
  information,
  mail,
  male,
  pricetag,
  refreshOutline,
  ticket,
  time,
  wine,
} from "ionicons/icons";

import { auth, db } from "../index";
import {
  DocumentData,
  Unsubscribe,
  addDoc,
  collection,
  doc,
  increment,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import "./TicketCompnent.css";
// HELPER
import _ from "underscore";
import { EventInterface } from "../pages/Admin";

interface TicketComponentProps {
  loggedIn: boolean;
}

type Ticket = {
  eventName: string;
  name: string;
  surname: string;
  boys: number;
  girls: number;
  email: string;
  phone: string;
  discount: number;
};

type Inputs = {
  name: string;
  description: string;
  drinks: string;
  price: number;
  commission: number;
  date: Date;
  time: string;
  location: string;
  capacity: number;
  eventId: string;
  duration: number;
  pax?: number;
  boys?: number;
  girls?: number;
};

const TicketComponent: React.FC<TicketComponentProps> = ({ loggedIn }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);
  const [allEvents, setAllEvents] = useState<Inputs[]>([]);
  const [fixedEvent, setFixedEvent] = useState<Inputs | null>(null);
  const [fixedEvent2, setFixedEvent2] = useState<Inputs | null>(null);
  const [fixedEvent3, setFixedEvent3] = useState<Inputs | null>(null);

  const [upcomingEvents, setUpcomingEvents] = useState<Inputs[]>([]);
  const [groupedEvents, setGroupedEvents] = useState<_.Dictionary<Inputs[]>>(
    {}
  );
  const [selectedEvents, setSelectedEvents] = useState<Inputs[]>([]);

  const [isSelectedEvents, setIsSelectedEvents] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const errorRef = useRef("");
  const successRef = useRef("");
  const selectedEventsRef = useRef<string[]>([]);

  let unsubscribe: Unsubscribe;

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<Ticket>();
  const onSubmit: SubmitHandler<Ticket> = (data: any) => calculatePrice(data);

  useEffect(() => {
    getAllEvents();

    // accordionGroup.current.value = ["first", "third"];

    return () => unsubscribe && unsubscribe();
  }, []);

  async function getAllEvents() {
    let fixEvent: any;
    let fixEvent2: any;
    let fixEvent3: any;
    const q = query(collection(db, "events"), where("active", "==", true));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const events: Inputs[] = [];
      querySnapshot.forEach((doc: DocumentData) => {
        const event: Inputs = doc.data();

        // Gozo Tour Supreme Cruise 30€

        if (event.name === " Daily Comino & Blue Lagoon Cruises 25€") {
          console.log(event);
        }
        events.push({ ...event, eventId: doc.id });

        if (event.name === "Daily Gozo Comino & Blue Lagoon Cruises 40€") {
          console.log("FIXXXED EVENT");
          fixEvent = { ...event, eventId: doc.id };
          setFixedEvent({ ...event, eventId: doc.id });
        }

        if (event.name === " Daily Comino & Blue Lagoon Cruises 25€") {
          console.log("2 FIXXXED EVENT");
          fixEvent2 = { ...event, eventId: doc.id };
          setFixedEvent2({ ...event, eventId: doc.id });
        }

        if (event.name === "Gozo Tour Supreme Cruise 30€") {
          console.log("3 FIXXXED EVENT");
          fixEvent3 = { ...event, eventId: doc.id };
          setFixedEvent3({ ...event, eventId: doc.id });
        }
      });

      console.log(fixEvent);

      setAllEvents(events);
      const today = new Date(Date.now());

      console.log(today);

      const yesterday = new Date(today);

      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      if (+time.split(":")[0] >= 3) {
        console.log("DELETE");
        yesterday.setDate(yesterday.getDate());
      } else {
        console.log("KEEP");
        yesterday.setDate(yesterday.getDate() - 1);
      }

      const todayDate =
        yesterday.getFullYear() +
        "/" +
        (yesterday.getMonth() + 1) +
        "/" +
        yesterday.getDate();

      // const eventDate =
      console.log(new Date(todayDate));

      console.log(time);

      const filteredEvents = events.filter(
        (e) =>
          new Date(e.date.toString().split("-").join("/")) >=
          new Date(todayDate)
      );

      // setUpcomingEvents([...filteredEvents, fixEvent, fixEvent2, fixEvent3]);
      setUpcomingEvents([...filteredEvents]);

      console.log(fixedEvent);

      const groupedEvents = _.groupBy(_.sortBy(filteredEvents, "date"), "date");

      console.log(groupedEvents);

      const sorted = _.sortBy(groupedEvents);
      console.log(sorted);
      setGroupedEvents(groupedEvents);
    });
  }

  async function selectEvent(checked: boolean, eventId: string) {
    console.log(checked, eventId);

    console.log(upcomingEvents);

    if (checked) {
      selectedEventsRef.current.push(eventId);
    } else {
      selectedEventsRef.current = selectedEventsRef.current.filter(
        (f) => f !== eventId
      );
    }

    console.log(selectedEventsRef.current);

    if (selectedEventsRef.current.length > 0) {
      setIsSelectedEvents(true);
    } else {
      setIsSelectedEvents(false);
    }
  }

  async function onNext() {
    const selectedEvents = upcomingEvents.filter((f) =>
      selectedEventsRef.current.includes(f.eventId)
    );

    console.log(selectedEvents);
    setSelectedEvents(selectedEvents);

    setNext(true);
  }

  async function onCancel(isReset: boolean) {
    selectedEventsRef.current = [];

    if (isReset) {
      // document.querySelectorAll("input[value="+checkboxValue+"]")[0].checked = false;

      const x = document.getElementsByClassName("checkbox");
      console.log(x);

      for (let index = 0; index < x.length; index++) {
        x[index].classList.remove("checkbox-checked");
      }
      reset();
    }
    setIsSelectedEvents(false);
    setNext(false);

    setTotalPrice(0);
  }

  async function calculatePrice(data: Ticket) {
    console.log(data);

    data.surname = await capitalizeWords(data.surname);
    data.name = await capitalizeWords(data.name);
    data.email = await capitalizeWords(data.email);

    console.log(data);
    let totalPrice = 0;

    const pax = +data.boys + +data.girls;
    console.log(pax);
    console.log(selectedEvents);

    selectedEvents.forEach((event) => {
      totalPrice += event.price * pax;
    });

    console.log(data.discount);

    if (data.discount) {
      totalPrice -= pax * +data.discount;
    }

    console.log(totalPrice);
    setTotalPrice(totalPrice);

    // const totalPrice =

    // createTicket(data);
  }

  async function createTicket(data: Ticket) {
    console.log("TICKET", data);

    data.surname = await capitalizeWords(data.surname);
    data.name = await capitalizeWords(data.name);
    data.email = await capitalizeWords(data.email);

    console.log(data);

    console.log(selectedEvents);
    console.log(selectedEventsRef.current);

    const selectedEventIds = selectedEventsRef.current;

    //! CALCULATE PRICE

    onCancel(true);
    reset();

    selectedEventIds.forEach(async (eventId) => {
      addDoc(collection(db, "tickets", "events", eventId), {
        eventName: allEvents.filter((e) => e.eventId === eventId)[0].name,
        commission: allEvents.filter((e) => e.eventId === eventId)[0]
          .commission,
        eventDate: allEvents.filter((e) => e.eventId === eventId)[0].date,
        eventTime: allEvents.filter((e) => e.eventId === eventId)[0].time,
        price:
          allEvents.filter((e) => e.eventId === eventId)[0].price -
          +data.discount / selectedEventIds.length,
        location: allEvents.filter((e) => e.eventId === eventId)[0].location,
        description: allEvents.filter((e) => e.eventId === eventId)[0]
          .description,
        duration: allEvents.filter((e) => e.eventId === eventId)[0].duration,
        boys: +data.boys,
        email: data.email,
        girls: +data.girls,
        pax: +data.girls + +data.boys,
        name: data.name,
        phone: data.phone,
        surname: data.surname,
        timestamp: Date.now(),
        seller: auth.currentUser?.displayName,
        uid: auth.currentUser?.uid,
        eventId: eventId,
        paid: false,
        checkin: false,
        commissionPaid: false,
        drinks: allEvents.filter((e) => e.eventId === eventId)[0].drinks,
        capacity: allEvents.filter((e) => e.eventId === eventId)[0].capacity,
      })
        .then(async (createdTicket) => {
          createQrCode(eventId, createdTicket.id, data.email);
          displaySuccess(
            `${selectedEventIds.length} Ticket(s) created successfully`
          );

          await setDoc(doc(db, "allTickets", createdTicket.id), {
            eventName: allEvents.filter((e) => e.eventId === eventId)[0].name,
            commission: allEvents.filter((e) => e.eventId === eventId)[0]
              .commission,
            eventDate: allEvents.filter((e) => e.eventId === eventId)[0].date,
            eventTime: allEvents.filter((e) => e.eventId === eventId)[0].time,
            price:
              allEvents.filter((e) => e.eventId === eventId)[0].price -
              +data.discount / selectedEventIds.length,
            location: allEvents.filter((e) => e.eventId === eventId)[0]
              .location,
            description: allEvents.filter((e) => e.eventId === eventId)[0]
              .description,
            duration: allEvents.filter((e) => e.eventId === eventId)[0]
              .duration,

            boys: +data.boys,
            email: data.email,
            girls: +data.girls,
            pax: +data.girls + +data.boys,
            name: data.name,
            phone: data.phone,
            surname: data.surname,
            timestamp: Date.now(),
            seller: auth.currentUser?.displayName,
            uid: auth.currentUser?.uid,
            eventId: eventId,
            paid: false,
            checkin: false,
            commissionPaid: false,
            drinks: allEvents.filter((e) => e.eventId === eventId)[0].drinks,
            capacity: allEvents.filter((e) => e.eventId === eventId)[0]
              .capacity,
          });

          // reset();

          const eventRef = doc(db, "events", eventId);
          setDoc(
            eventRef,
            {
              pax: increment(+data.boys + +data.girls),
              boys: increment(+data.boys),
              girls: increment(+data.girls),
            },
            { merge: true }
          );
        })
        .catch((error) => {
          displayError(error);
        });
    });

    return;
  }

  async function createQrCode(
    eventId: string,
    ticketId: string,
    email: string
  ) {
    console.log(eventId, ticketId);

    const ticketUrl = `https://aycp.eu/customer/eventId=${eventId}&ticketId=${ticketId}`;

    await sendTicket(email, ticketUrl);
    await sendTicket("info@aycp.eu", ticketUrl);
    if (auth.currentUser) {
      await sendTicket(auth.currentUser.email!, ticketUrl);
    }

    // addDoc(collection(db, "mail"), {
    //   to: [email],
    //   message: {
    //     subject: "AYCP-Ticket",
    //     text: "This is the plaintext section of the email body.",
    //     html: `This is your Ticket  <code> <br/> <br/>      <p style="text-align: center">
    //     <a
    //       style="background: #00dbfb; color: white; padding: 1%; "
    //       href="${ticketUrl}"
    //       target="_blank"
    //       >Get your ticket</a
    //     >
    //   </p><br/> <br/>for event: for event: ${eventId} </code> `,
    //   },
    // });
  }

  async function sendTicket(email: string, ticketUrl: string) {
    await addDoc(collection(db, "mail"), {
      to: [email],
      message: {
        subject: "AYCP-Ticket",
        text: "HERE IS YOUR AYCP TICKET",
        html: `<code> <br/> <br/>     
        <div style="text-align: center;">
        <h1 style="font-weight: bold; font-size: 32px;">ITS TIME TO PARTY</h1>
        <img
        width="250"
        src="https://i.ibb.co/t3VPnZ5/icon.png"        ></img>
        <h3 style="font-weight: lighter;" >
          YOU CAN FIND YOUR TICKET & DETAILS OF YOUR PARTY
        </h3>
        <br />

        <a
          style="background: #00dbfb; color: black; padding: 1%; text-decoration: none; padding: 15px; font-weight: bold; border-radius: 60%;"
          href="${ticketUrl}"
          target="_blank"
        >
          CLICK HERE
        </a>

        <br />
        <br />
        <br />
        <h6 style="margin-right: 5%; margin-left: 5%; font-weight: lighter; ">
        <b>Terms and conditions</b>
          No refund on tickets will be made under any circumstances unless
          otherwise instructed by the event organizer. If a refund is issued for
          tickets bought, a refund will be issued on the tickets face value.
          Booking fees will NOT be refunded. The Organiser shall not be
          responsible for any loss, damage, death or injury howsoever caused
          unless (i) the relevant party has breached its legal obligations; and
          (ii) such loss or damage is a direct and reasonably foreseeable result
          of the breach. For the avoidance of doubt, the Organiser will not be
          responsible for the actions of any third party, including, but not
          limited to, the exhibitors or performers. If all or any of the Event
          is planned to take place outdoors it will take place regardless of
          weather conditions unless deemed unsafe by the safety officer. Whilst
          every attempt shall be made to secure a replacement indoor venue,
          there shall be no refunds for bad weather if the event takes place.
          The Organiser does not accept any responsibility for any loss or
          damage to personal property brought to the Event. Events involving
          clubbing and drinks often have legal requirements for age. Anyone
          under age will be refused entry. In accordance with the law, visitors
          aged under 17 are not permitted to purchase/sample alcoholic drinks.
          The relevant Maltese law will apply to these Terms and Conditions and
          Courts of Malta will have exclusive jurisdiction in relation to the
          Terms and Conditions. Shows happening Terms and Conditions
        </h6>
      </div>
        
        </code> `,
      },
    });
  }

  async function displayError(error: { message: string }) {
    console.log(error);
    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function displaySuccess(successMessage: string) {
    console.log("MESSAGEE");
    successRef.current = successMessage;
    setIsOpenSuccess(true);

    setTimeout(() => {
      setIsOpenSuccess(false);
    }, 5000);
  }

  async function capitalizeWords(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div>
      <IonHeader class="headers">
        <IonToolbar color="primary" className="ion-text-center">
          <IonTitle color="light" style={{ fontSize: "20px" }}>
            <b>TICKET</b>
          </IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => onCancel(true)}>
              <IonIcon
                slot="icon-only"
                icon={refreshOutline}
                color="light"
                size="large"
              ></IonIcon>
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              onClick={() => onNext()}
              disabled={!isSelectedEvents || !loggedIn}
            >
              <IonIcon
                slot="icon-only"
                icon={arrowForward}
                color="light"
                size="large"
              ></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonGrid className="ion-no-padding">
        <div>
          {!next && (
            <IonList>
              <h2 className="ion-text-center">
                <b>EVENTS</b>
              </h2>
              <>
                {fixedEvent && (
                  <IonRow>
                    <IonCol sizeSm="4" offsetSm="4">
                      <IonCard
                        className="ion-no-margin"
                        style={{ marginBottom: "10px" }}
                      >
                        <IonImg src={`/assets/images/${"COMINOGOZO"}.jpg`} />
                        <IonCardHeader>
                          <IonCardTitle>{fixedEvent.name} </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>
                            <IonCol size="3">
                              <IonIcon
                                icon={time}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent.time}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={wine}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent.drinks}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={cash}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent.price}
                              </IonLabel>
                            </IonCol>{" "}
                            <IonCol size="4">
                              <IonIcon
                                icon={ticket}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent.pax ? fixedEvent.pax : 0}/
                                {fixedEvent.capacity}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="1">
                              <IonCheckbox
                                style={{ marginTop: "-3px" }}
                                className="checkbox"
                                key={fixedEvent.eventId}
                                disabled={
                                  +(fixedEvent.pax ? fixedEvent.pax : 0) >=
                                  fixedEvent.capacity
                                }
                                onIonChange={(e) =>
                                  selectEvent(
                                    e.detail.checked,
                                    fixedEvent.eventId
                                  )
                                }
                              ></IonCheckbox>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                )}

                {fixedEvent2 && (
                  <IonRow>
                    <IonCol sizeSm="4" offsetSm="4">
                      <IonCard
                        className="ion-no-margin"
                        style={{ marginBottom: "10px" }}
                      >
                        <IonImg src={`/assets/images/${"COMINO"}.jpg`} />
                        <IonCardHeader>
                          <IonCardTitle>{fixedEvent2.name} </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>
                            <IonCol size="3">
                              <IonIcon
                                icon={time}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent2.time}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={wine}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent2.drinks}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={cash}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent2.price}
                              </IonLabel>
                            </IonCol>{" "}
                            <IonCol size="4">
                              <IonIcon
                                icon={ticket}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent2.pax ? fixedEvent2.pax : 0}/
                                {fixedEvent2.capacity}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="1">
                              <IonCheckbox
                                style={{ marginTop: "-3px" }}
                                className="checkbox"
                                key={fixedEvent2.eventId}
                                disabled={
                                  +(fixedEvent2.pax ? fixedEvent2.pax : 0) >=
                                  fixedEvent2.capacity
                                }
                                onIonChange={(e) =>
                                  selectEvent(
                                    e.detail.checked,
                                    fixedEvent2.eventId
                                  )
                                }
                              ></IonCheckbox>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                )}

                {fixedEvent3 && (
                  <IonRow>
                    <IonCol sizeSm="4" offsetSm="4">
                      <IonCard
                        className="ion-no-margin"
                        style={{ marginBottom: "10px" }}
                      >
                        <IonImg src={`/assets/images/${"GOZO"}.jpg`} />
                        <IonCardHeader>
                          <IonCardTitle>{fixedEvent3.name} </IonCardTitle>
                        </IonCardHeader>

                        <IonCardContent>
                          <IonRow>
                            <IonCol size="3">
                              <IonIcon
                                icon={time}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent3.time}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={wine}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent3.drinks}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="2">
                              <IonIcon
                                icon={cash}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent3.price}
                              </IonLabel>
                            </IonCol>{" "}
                            <IonCol size="4">
                              <IonIcon
                                icon={ticket}
                                size="default"
                                color="primary"
                              ></IonIcon>
                              &nbsp;
                              <IonLabel style={{ fontWeight: "bold" }}>
                                {fixedEvent3.pax ? fixedEvent3.pax : 0}/
                                {fixedEvent3.capacity}
                              </IonLabel>
                            </IonCol>
                            <IonCol size="1">
                              <IonCheckbox
                                style={{ marginTop: "-3px" }}
                                className="checkbox"
                                key={fixedEvent3.eventId}
                                disabled={
                                  +(fixedEvent3.pax ? fixedEvent3.pax : 0) >=
                                  fixedEvent3.capacity
                                }
                                onIonChange={(e) =>
                                  selectEvent(
                                    e.detail.checked,
                                    fixedEvent3.eventId
                                  )
                                }
                              ></IonCheckbox>
                            </IonCol>
                          </IonRow>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                )}
              </>
              {Object.entries(groupedEvents).length === 0 && (
                <h2 className="ion-text-center">NO EVENTS AVAILABLE</h2>
              )}

              <IonRow>
                <IonCol sizeSm="4" offsetSm="4">
                  {groupedEvents && (
                    <IonAccordionGroup multiple={true}>
                      {Object.entries(groupedEvents).map((eventObj, i) => {
                        return (
                          <IonAccordion key={i}>
                            <IonItem slot="header" color="dark">
                              <IonIcon
                                icon={calendarOutline}
                                className="ion-padding"
                              ></IonIcon>
                              <IonLabel className="ion-text-center">
                                <b>
                                  {eventObj[0].split("-").reverse().join(".")}
                                </b>
                              </IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                              {eventObj[1].map((event) => {
                                console.log(event);
                                return (
                                  <>
                                    <IonCard
                                      className="ion-no-margin"
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <IonImg
                                        src={`/assets/images/${
                                          event.description.split("_/")[0]
                                        }.jpg`}
                                      />
                                      <IonCardHeader>
                                        <IonRow>
                                          <IonCol size="11">
                                            {" "}
                                            <IonCardTitle>
                                              {event.name}{" "}
                                            </IonCardTitle>
                                          </IonCol>
                                          <IonCol size="1">
                                            <IonIcon
                                              icon={information}
                                              size="large"
                                              color="primary"
                                              onClick={() =>
                                                displaySuccess(
                                                  event.description.includes(
                                                    "_/"
                                                  )
                                                    ? event.description
                                                        .split("_/" && "_/")[1]
                                                        .split("--/" && "-/")
                                                        .join(" ")
                                                      ? event.description
                                                          .split(
                                                            "_/" && "_/"
                                                          )[1]
                                                          .split("--/" && "-/")
                                                          .join(" ")
                                                      : "NO DESCRIPTION"
                                                    : "NO DESCRIPTION"
                                                )
                                              }
                                            ></IonIcon>
                                          </IonCol>
                                        </IonRow>
                                      </IonCardHeader>

                                      <IonCardContent>
                                        <IonRow>
                                          <IonCol size="3">
                                            <IonIcon
                                              icon={time}
                                              size="default"
                                              color="primary"
                                            ></IonIcon>
                                            &nbsp;
                                            <IonLabel
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {event.time}
                                            </IonLabel>
                                          </IonCol>
                                          <IonCol size="2">
                                            <IonIcon
                                              icon={wine}
                                              size="default"
                                              color="primary"
                                            ></IonIcon>
                                            &nbsp;
                                            <IonLabel
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {event.drinks === "OPEN BAR"
                                                ? "OB"
                                                : event.drinks}
                                            </IonLabel>
                                          </IonCol>
                                          <IonCol size="2">
                                            <IonIcon
                                              icon={cash}
                                              size="default"
                                              color="primary"
                                            ></IonIcon>
                                            &nbsp;
                                            <IonLabel
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {event.price}
                                            </IonLabel>
                                          </IonCol>{" "}
                                          <IonCol size="4">
                                            <IonIcon
                                              icon={ticket}
                                              size="default"
                                              color="primary"
                                            ></IonIcon>
                                            &nbsp;
                                            <IonLabel
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {event.pax ? event.pax : 0}/
                                              {event.capacity}
                                            </IonLabel>
                                          </IonCol>
                                          <IonCol size="1">
                                            <IonCheckbox
                                              style={{ marginTop: "-3px" }}
                                              className="checkbox"
                                              key={event.eventId}
                                              disabled={
                                                +(event.pax ? event.pax : 0) >=
                                                event.capacity
                                              }
                                              onIonChange={(e) =>
                                                selectEvent(
                                                  e.detail.checked,
                                                  event.eventId
                                                )
                                              }
                                            >
                                              {/* <b>
                                          {`${event.name} ${event.time} ${
                                            event.drinks
                                          } Drinks ${event.price}€ ${
                                            event.pax ? event.pax : 0
                                          }/${event.capacity}`}
                                        </b> */}
                                            </IonCheckbox>
                                          </IonCol>
                                        </IonRow>
                                      </IonCardContent>
                                    </IonCard>
                                  </>
                                );
                              })}
                            </div>
                          </IonAccordion>
                        );

                        {
                          /* {eventObj[1].map((event) => {
                    return (
                      <IonItem key={event.eventId}>
                        <IonCheckbox
                          key={event.eventId}
                          onIonChange={(e) =>
                            selectEvent(e.detail.checked, event.eventId)
                          }
                        >
                          <IonLabel>
                            {`${event.name} ${event.date
                              .toString()
                              .split("-")
                              .reverse()
                              .join(".")} ${event.time} ${
                              event.pax ? event.pax : 0
                            }/${event.capacity}`}
                          </IonLabel>
                        </IonCheckbox>
                      </IonItem>
                    );
                  })} */
                        }
                      })}
                    </IonAccordionGroup>
                  )}
                </IonCol>
              </IonRow>

              {/* {allEvents.map((event) => {
            return (
              <IonItem key={event.eventId}>
                <IonCheckbox
                  key={event.eventId}
                  onIonChange={(e) =>
                    selectEvent(e.detail.checked, event.eventId)
                  }
                >
                  <IonLabel>
                    {`${event.name} ${event.date
                      .toString()
                      .split("-")
                      .reverse()
                      .join(".")} ${event.time} ${event.pax ? event.pax : 0}/${
                      event.capacity
                    }`}
                  </IonLabel>
                </IonCheckbox>
              </IonItem>
            );
          })} */}
            </IonList>
          )}
          <div>
            {next && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className="ion-text-center">ENTER THE TICKET DETAILS</h2>
                {selectedEvents.map((event) => {
                  return (
                    <h3 className="ion-text-center" key={event.eventId}>{`${
                      event.name
                    } ${event.date.toString().split("-").reverse().join(".")} ${
                      event.time
                    }`}</h3>
                  );
                })}

                <IonGrid>
                  <IonList>
                    <IonRow>
                      <IonCol sizeSm="4" offsetSm="4" size="12">
                        <IonItem lines="full">
                          <IonInput
                            {...register("surname", { required: true })}
                            label="Surname"
                            type="text"
                            placeholder="Please enter the surname"
                            labelPlacement="floating"
                          ></IonInput>
                          <IonIcon icon={accessibility}></IonIcon>
                        </IonItem>
                        {errors.surname && <span>This field is required</span>}
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol sizeSm="4" offsetSm="4" size="12">
                        <IonItem lines="full">
                          <IonInput
                            {...register("name", { required: true })}
                            label="Name"
                            type="text"
                            placeholder="Please enter the name"
                            labelPlacement="floating"
                          ></IonInput>
                          <IonIcon icon={help}></IonIcon>
                        </IonItem>
                        {errors.name && <span>This field is required</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeSm="4" offsetSm="4" size="12">
                        <IonItem lines="full">
                          <IonInput
                            // onIonChange={(e: any) => addPax(e.target.value)}
                            {...register("boys", { required: true })}
                            label="Boys"
                            type="number"
                            placeholder="Please enter number of boys"
                            labelPlacement="floating"
                            autoCapitalize="true"
                          ></IonInput>
                          <IonIcon icon={male}></IonIcon>
                        </IonItem>
                        {errors.boys && <span>This field is required</span>}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeSm="4" offsetSm="4" size="12">
                        <IonItem lines="full">
                          <IonInput
                            {...register("girls", { required: true })}
                            label="Girls"
                            type="number"
                            placeholder="Please enter the number of girls"
                            labelPlacement="floating"
                          ></IonInput>
                          <IonIcon icon={female}></IonIcon>
                        </IonItem>
                        {errors.girls && <span>This field is required</span>}
                        <IonItem lines="full">
                          <IonInput
                            {...register("email", { required: true })}
                            label="Email"
                            type="email"
                            placeholder="Please enter the Email"
                            labelPlacement="floating"
                          ></IonInput>
                          <IonIcon icon={mail}></IonIcon>
                        </IonItem>
                        {errors.email && (
                          <span>
                            This field is required {errors.email?.message}
                          </span>
                        )}
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol sizeSm="4" offsetSm="4" size="12">
                        <IonItem lines="full">
                          <IonInput
                            {...register("phone", { required: true })}
                            label="Phone"
                            type="tel"
                            placeholder="Please enter the phone number"
                            labelPlacement="floating"
                          ></IonInput>
                          <IonIcon icon={call}></IonIcon>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    {errors.phone && (
                      <span>
                        This field is required {errors.phone?.message}
                      </span>
                    )}

                    {auth.currentUser &&
                      [
                        "hxIVnzwEYuW9SfvrFCrb6PVaqeE2",
                        "BjxHKgfqZoasYTFjLaDCZZoWRYw1",
                      ].includes(auth.currentUser.uid) && (
                        <IonRow>
                          <IonCol sizeSm="4" offsetSm="4" size="12">
                            <IonItem lines="full">
                              <IonInput
                                {...register("discount", { required: true })}
                                label="Discount"
                                type="number"
                                value={0}
                                placeholder="Please enter the discount per person"
                                labelPlacement="floating"
                              ></IonInput>
                              <IonIcon icon={pricetag}></IonIcon>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      )}
                    {errors.discount && (
                      <span>
                        This field is required {errors.discount?.message}
                      </span>
                    )}
                    {totalPrice > 0 && (
                      <h1 className="ion-text-center">
                        TOTAL PRICE: {totalPrice}€
                      </h1>
                    )}
                    <IonRow className="ion-padding">
                      <IonCol size="6" sizeSm="2" offsetSm="4">
                        <IonButton
                          color="dark"
                          expand="block"
                          size="small"
                          onClick={() => onCancel(false)}
                        >
                          BACK
                        </IonButton>
                      </IonCol>
                      <IonCol size="6" sizeSm="2">
                        {" "}
                        <IonButton
                          color="dark"
                          expand="block"
                          size="small"
                          type="submit"
                          disabled={!isValid || !loggedIn}
                        >
                          CREATE
                        </IonButton>
                      </IonCol>

                      {/* <IonCol size="4">
                    <IonButton
                      color="dark"
                      expand="block"
                      size="small"
                      onClick={() => onCancel(true)}
                    >
                      RESET
                    </IonButton>
                  </IonCol> */}
                    </IonRow>
                  </IonList>
                </IonGrid>
              </form>
            )}

            <IonToast
              style={{ textAlign: "center" }}
              isOpen={isOpen}
              message={"ERROR: " + errorRef.current}
              icon={alertOutline}
              buttons={[
                {
                  text: "OK",
                  role: "cancel",
                  handler: () => {
                    setIsOpen(false);
                  },
                },
              ]}
            ></IonToast>
            <IonToast
              style={{ textAlign: "center" }}
              isOpen={isOpenSucess}
              message={successRef.current}
              duration={5000}
              icon={checkmarkOutline}
            ></IonToast>

            <IonAlert
              header={`TOTAL PRICE ${totalPrice}€`}
              isOpen={totalPrice > 0}
              buttons={[
                {
                  text: "CANCEL",
                  role: "cancel",
                  handler: () => {
                    console.log("Alert canceled");
                    setTotalPrice(0);
                  },
                },
                {
                  text: "SELL",
                  role: "confirm",
                  handler: () => {
                    createTicket(getValues());
                  },
                },
              ]}
              onDidDismiss={({ detail }) =>
                console.log(`Dismissed with role: ${detail.role}`)
              }
            ></IonAlert>
          </div>
        </div>
      </IonGrid>
    </div>
  );
};

export default TicketComponent;
