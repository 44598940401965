import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import "./QRScanner.css";
import Header from "../components/Header";
import QRScannerComponent from "../components/QRScannerComponent";

interface QRScannerProps {
  loggedIn: boolean;
}
const QRScanner: React.FC<QRScannerProps> = ({ loggedIn }) => {
  // const getCamera = async () => {
  //   if (!navigator.mediaDevices) {
  //     throw new Error("mediaDevices API unavailable.");
  //   }

  //   const devices = await navigator.mediaDevices.enumerateDevices();
  //   const cameras = devices.filter((d) => d.kind === "videoinput");
  //   return cameras[0];
  // };
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header pageTtitle="QR-Scanner"></Header>
        <QRScannerComponent loggedIn={loggedIn} />
      </IonContent>
    </IonPage>
  );
};

export default QRScanner;
