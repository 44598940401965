import React, { useCallback, useEffect, useState } from "react";
import {
  IonContent,
  IonPage,
  IonMenu,
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonListHeader,
  IonAccordion,
  IonAccordionGroup,
  IonImg,
} from "@ionic/react";

import "./Admin.css";
import Header from "../components/Header";
import {
  cashOutline,
  accessibility,
  add,
  list,
  albums,
  cash,
  trendingUp,
  pricetag,
  analytics,
  barChart,
  documentText,
} from "ionicons/icons";
import CreateSeller from "../components/admin/seller/CreateSeller";
import CreateEvents from "../components/admin/events/CreateEvents";
import AllSeller from "../components/admin/seller/AllSeller";
import AllEvents from "../components/admin/events/allEvents";
import Analytics, {
  Seller,
  Ticket,
} from "../components/admin/analytics/Analytics";
import Reports from "../components/admin/analytics/reports";
import Payouts from "../components/admin/finance/Payouts";
import { unsubscribe } from "diagnostics_channel";
import {
  query,
  collection,
  onSnapshot,
  DocumentData,
  Unsubscribe,
  getDocs,
  DocumentSnapshot,
} from "firebase/firestore";
import _ from "underscore";
import { db } from "..";
import { TicketInterface } from "../components/QRScannerComponent";

interface AdminPageProps {
  loggedIn: boolean;
}

export interface EventInterface {
  eventId: string;
  name: string;
  location: string;
  price: number;
  commission: number;
  date: string;
  time: string;
  active: boolean;
}

const Admin: React.FC<AdminPageProps> = ({ loggedIn }) => {
  const [allSoldTickets, setAllSoldTicketsFromSeller] = useState<Ticket[]>([]);
  const [allEvents, setAllEvents] = useState<EventInterface[]>([]);
  const [allSeller, setAllSeller] = useState<Seller[]>([]);

  let unsubscribe: Unsubscribe;

  useEffect(() => {
    console.log("ADMINNNN");
    getData();

    return () => unsubscribe && unsubscribe();
  }, []);
  const [adminComponent, setAdminComponent] = useState<
    | "createSeller"
    | "allSeller"
    | "createEvents"
    | "allEvents"
    | "commission"
    | "payouts"
    | "analytics"
    | "reports"
  >("createSeller");
  const menuRef = React.useRef<HTMLIonMenuElement>(null);

  async function onMenuClicked(
    component:
      | "createSeller"
      | "allSeller"
      | "createEvents"
      | "allEvents"
      | "commission"
      | "payouts"
      | "analytics"
      | "reports"
  ) {
    menuRef.current?.close();
    setAdminComponent(component);
  }

  async function getData() {
    console.log("DATATAAT");
    const eventsQuery = query(collection(db, "events"));

    const allSoldTicketsArr: Ticket[] = [];

    const eventsQuerySnapshot = await getDocs(eventsQuery);
    const events: EventInterface[] = [];
    eventsQuerySnapshot.forEach(async (eventData: DocumentData) => {
      const event = eventData.data();
      events.push({
        eventId: eventData.id,
        name: event.name,
        location: event.location,
        price: event.price,
        commission: event.commission,
        date: event.date,
        time: event.time,
        active: event.active,
      });
    });

    const allSeller: Seller[] = [];
    const q = query(collection(db, "seller"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc: DocumentData) => {
      allSeller.push({ ...doc.data(), sellerId: doc.id });
    });

    for (let i = 0; i < events.length; i++) {
      const ticketsQuery = query(
        collection(db, "tickets", "events", events[i].eventId)
      );

      unsubscribe = onSnapshot(ticketsQuery, (ticketsQuerySnapshot) => {
        console.log("FETCH");
        ticketsQuerySnapshot.forEach((ticketData: DocumentData) => {
          const ticket: Ticket = ticketData.data();

          const allTicketsIndex = allSoldTicketsArr.findIndex(
            (soldTicket) =>
              soldTicket.eventId === ticket.eventId &&
              soldTicket.timestamp === ticket.timestamp
          );

          if (events[i].price === ticket.price) {
            console.log(events[i]);
            console.log(ticket);
            console.log("HHHHHHHHHHHHHHHHH");
          }

          if (allTicketsIndex !== -1) {
            allSoldTicketsArr[allTicketsIndex] = {
              ...ticket,
              ticketId: ticketData.id,
              commission: events[i].commission,
            };
          } else {
            if (ticket.seller === "Shop 2") {
              console.log(ticket);
              console.log(events[i]);
            }
            allSoldTicketsArr.push({
              ...ticket,
              ticketId: ticketData.id,
              price:
                ticket.seller === "Shop 1" || ticket.seller === "Shop 2"
                  ? ticket.price
                  : events[i].price,
              active: events[i].active,
              commission: events[i].commission,
            });
          }
        });

        if (i === events.length - 1) {
          setAllSoldTicketsFromSeller(
            _.sortBy(allSoldTicketsArr, "timestamp").reverse()
          );

          console.log("xxxxxxxxxxxxxxxxxxxxx");
          console.log(allSoldTicketsArr);
          setAllSeller(allSeller);
          setAllEvents(events);
        }
      });
    }
  }

  return (
    <>
      <IonMenu contentId="main-content" ref={menuRef} style={{ padding: 0 }}>
        <IonContent>
          <IonList>
            <IonListHeader style={{ marginBottom: "10%" }}>
              <b>Admin Menu</b>
            </IonListHeader>
            <IonAccordionGroup multiple={true}>
              <IonAccordion value="first">
                <IonItem slot="header" color="dark">
                  <IonIcon
                    icon={accessibility}
                    className="ion-padding"
                  ></IonIcon>
                  <IonLabel> Seller</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("createSeller")}
                  >
                    <IonIcon icon={add} className="ion-padding"></IonIcon>
                    <IonLabel> Create seller</IonLabel>
                  </IonItem>

                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("allSeller")}
                  >
                    <IonIcon icon={list} className="ion-padding"></IonIcon>
                    <IonLabel> All seller</IonLabel>
                  </IonItem>
                </div>
              </IonAccordion>
              <IonAccordion value="second">
                <IonItem slot="header" color="dark">
                  <IonIcon icon={albums} className="ion-padding"></IonIcon>
                  <IonLabel>Events</IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("createEvents")}
                  >
                    <IonIcon icon={add} className="ion-padding"></IonIcon>
                    <IonLabel> Create event</IonLabel>
                  </IonItem>
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("allEvents")}
                  >
                    <IonIcon icon={list} className="ion-padding"></IonIcon>
                    <IonLabel> All events</IonLabel>
                  </IonItem>{" "}
                </div>
              </IonAccordion>
              <IonAccordion value="third">
                <IonItem slot="header" color="dark">
                  <IonIcon icon={cash} className="ion-padding"></IonIcon>
                  <IonLabel>Finance</IonLabel>{" "}
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("payouts")}
                  >
                    <IonIcon
                      icon={trendingUp}
                      className="ion-padding"
                    ></IonIcon>
                    <IonLabel>Payouts</IonLabel>
                  </IonItem>
                </div>
              </IonAccordion>
              <IonAccordion value="fourth">
                <IonItem slot="header" color="dark">
                  <IonIcon icon={analytics} className="ion-padding"></IonIcon>
                  <IonLabel>Analytics</IonLabel>{" "}
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("analytics")}
                  >
                    <IonIcon icon={barChart} className="ion-padding"></IonIcon>
                    <IonLabel>Playground</IonLabel>
                  </IonItem>
                  <IonItem
                    lines="none"
                    type="button"
                    onClick={() => onMenuClicked("reports")}
                  >
                    <IonIcon
                      icon={documentText}
                      className="ion-padding"
                    ></IonIcon>
                    <IonLabel>Reports</IonLabel>
                  </IonItem>
                </div>
              </IonAccordion>
            </IonAccordionGroup>

            <IonImg
              src="/assets/icon/icon.png"
              style={{
                width: "50%",
                heght: "50%",
                marginTop: "50%",
                marginLeft: "25%",
              }}
            ></IonImg>
          </IonList>
        </IonContent>
      </IonMenu>
      <IonPage id="main-content">
        <Header pageTtitle="Admin Dashboard" />

        <IonContent className="ion-no-padding">
          {adminComponent === "createSeller" && (
            <CreateSeller loggedIn={loggedIn} />
          )}
          {adminComponent === "allSeller" && <AllSeller loggedIn={loggedIn} />}
          {adminComponent === "createEvents" && (
            <CreateEvents loggedIn={loggedIn} />
          )}
          {adminComponent === "allEvents" && <AllEvents loggedIn={loggedIn} />}
          {adminComponent === "payouts" && (
            <Payouts
              loggedIn={loggedIn}
              allEvents={allEvents}
              allSeller={allSeller}
              allSoldTickets={allSoldTickets}
              getData={getData}
            />
          )}
          {adminComponent === "analytics" && (
            <Analytics
              loggedIn={loggedIn}
              allSoldTickets={allSoldTickets}
              allEvents={allEvents}
              allSeller={allSeller}
              getData={getData}
            />
          )}
          {adminComponent === "reports" && (
            <Reports loggedIn={loggedIn} allSoldTickets={allSoldTickets} />
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Admin;
