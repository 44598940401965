import React, { RefObject, useEffect, useRef, useState } from "react";
import "./CreateSeller.css";

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSearchbar,
} from "@ionic/react";

import { auth, db } from "../../..";
import { DocumentData, collection, getDocs, query } from "firebase/firestore";
import _ from "underscore";
import { add, save } from "ionicons/icons";
import { updatePassword } from "firebase/auth";

interface CreateSellerComponent {
  loggedIn: boolean;
}

const AllSeller: React.FC<CreateSellerComponent> = ({ loggedIn }) => {
  console.log(loggedIn);
  // const [isOpen, setIsOpen] = useState<boolean>(false);

  // console.log(isOpen, loggedIn);

  type Seller = {
    displayName: string;
    email: string;
    password: string;
    timestamp: number;
    sellerId: string;
    accountType?: "seller" | "qrSeller" | "admin";
  };

  const [allSeller, setAllSeller] = useState<Seller[]>([]);
  const [filteredSeller, setFilteredSeller] = useState<Seller[]>([]);

  // const errorRef = useRef("");

  useEffect(() => {
    getAllSeller();
  }, []);

  async function getAllSeller() {
    const q = query(collection(db, "seller"));
    const seller: {
      displayName: string;
      email: string;
      password: string;
      timestamp: number;
      sellerId: string;
    }[] = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: DocumentData) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      seller.push({ ...doc.data(), sellerId: doc.id });
    });

    setAllSeller(seller);
    setFilteredSeller(seller);
  }

  async function handleSearch(ev: Event) {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    console.log(query);
    if (query) {
      const filteredSeller = allSeller.filter(
        (t) =>
          t.displayName.toLowerCase().indexOf(query) > -1 ||
          t.email.toLowerCase().indexOf(query) > -1 ||
          t.password.toLowerCase().indexOf(query) > -1
      );

      setFilteredSeller(filteredSeller);

      console.log(filteredSeller);

      // setFilteredallSoldTicketsFromSeller(filteredTickets);
    } else {
      setFilteredSeller(allSeller);
      // setFilteredallSoldTicketsFromSeller(allSoldTicketsFromSellers);
    }
  }

  // async function displayError(error: { message: string }) {
  //   console.log(error);
  //   errorRef.current = error.message;
  //   setIsOpen(true);

  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 15000);
  // }
  return (
    <div>
      {/* <div style={{ textAlign: "center" }}>
        <h1 style={{ fontWeight: "bold" }}>IT`S TIME TO PARTY</h1>
        <img
          src="/assets/icon/icon.png"
          className="ion-text-center"
          style={{ width: "10%" }}
        ></img>
        <h3 style={{ fontWeight: "bold" }}>
          YOU CAN FIND YOUR TICKET & DETAILS OF YOUR PARTY
        </h3>
        <br />

        <a
          // style="background: #00dbfb; color: white; padding: 1%; "
          style={{
            background: "#00dbfb",
            color: "black",
            textDecorationLine: "none",
            padding: "15px",
            fontWeight: "bold",
            borderRadius: "60%",
            border: "3px solid black",
          }}
          href="${ticketUrl}"
          target="_blank"
        >
          CLICK HERE
        </a>

        <br />
        <br />
        <br />

        <h3 style={{ fontWeight: "bold" }}>Terms and conditions :</h3>
        <h6 style={{ marginRight: "100px", marginLeft: "100px" }}>
          No refund on tickets will be made under any circumstances unless
          otherwise instructed by the event organizer. If a refund is issued for
          tickets bought, a refund will be issued on the tickets face value.
          Booking fees will NOT be refunded. The Organiser shall not be
          responsible for any loss, damage, death or injury howsoever caused
          unless (i) the relevant party has breached its legal obligations; and
          (ii) such loss or damage is a direct and reasonably foreseeable result
          of the breach. For the avoidance of doubt, the Organiser will not be
          responsible for the actions of any third party, including, but not
          limited to, the exhibitors or performers. If all or any of the Event
          is planned to take place outdoors it will take place regardless of
          weather conditions unless deemed unsafe by the safety officer. Whilst
          every attempt shall be made to secure a replacement indoor venue,
          there shall be no refunds for bad weather if the event takes place.
          The Organiser does not accept any responsibility for any loss or
          damage to personal property brought to the Event. Events involving
          clubbing and drinks often have legal requirements for age. Anyone
          under age will be refused entry. In accordance with the law, visitors
          aged under 17 are not permitted to purchase/sample alcoholic drinks.
          The relevant Maltese law will apply to these Terms and Conditions and
          Courts of Malta will have exclusive jurisdiction in relation to the
          Terms and Conditions. Shows happening Terms and Conditions
        </h6>
      </div> */}
      <IonGrid>
        <IonRow>
          <IonCol sizeSm="4" offsetSm="4">
            <h2 className="ion-text-center">
              <b>ALL SELLER</b>
            </h2>
            <IonSearchbar
              className="ion-no-padding"
              color="dark"
              key="searchbar"
              animated={true}
              placeholder="search"
              debounce={500}
              onIonInput={(ev) => handleSearch(ev)}
            ></IonSearchbar>
          </IonCol>
        </IonRow>
        <IonList>
          <IonRow key={"seller.sellerId"} style={{ border: "1px solid" }}>
            <IonCol size="3 " sizeSm="1" offsetSm="1">
              <IonLabel style={{ fontWeight: "bold" }}>TYPE</IonLabel>
            </IonCol>
            <IonCol size="3" sizeSm="2" offsetSm="1">
              <IonLabel style={{ fontWeight: "bold" }}>NAME </IonLabel>
            </IonCol>
            <IonCol size="3" sizeSm="2.5" offsetSm="1">
              <IonLabel style={{ fontWeight: "bold" }}>EMAIL </IonLabel>
            </IonCol>
            <IonCol size="3" sizeSm="1" offsetSm="1">
              <IonLabel style={{ fontWeight: "bold" }}>PW </IonLabel>
            </IonCol>
          </IonRow>
          {_.sortBy(filteredSeller, "displayName").map((seller) => {
            return (
              <>
                {/* // eslint-disable-next-line react/jsx-key */}
                <IonRow key={seller.sellerId} style={{ border: "1px solid" }}>
                  <IonCol size="3 " sizeSm="1" offsetSm="1">
                    <IonLabel>{seller.accountType + " "}</IonLabel>
                  </IonCol>
                  <IonCol size="3" sizeSm="2" offsetSm="1">
                    <IonLabel>{seller.displayName + " "} </IonLabel>
                  </IonCol>
                  <IonCol size="3" sizeSm="2.5" offsetSm="1">
                    <IonLabel>{seller.email + " "} </IonLabel>
                  </IonCol>
                  <IonCol size="3" sizeSm="2" offsetSm="1">
                    <IonLabel>{seller.password}</IonLabel>
                  </IonCol>
                </IonRow>
              </>
            );
          })}
        </IonList>
      </IonGrid>
    </div>
  );
};

export default AllSeller;
