import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Auth.css";
import Header from "../components/Header";
import FinanceComponent, { Ticket } from "../components/FinanceComponent";
import { Redirect } from "react-router";
import {
  query,
  collection,
  getDocs,
  DocumentData,
  onSnapshot,
  where,
} from "firebase/firestore";
import { auth, db } from "..";
import { EventInterface } from "./Admin";
import _ from "underscore";

interface FinanceProps {
  loggedIn: boolean;
}

const Finance: React.FC<FinanceProps> = ({ loggedIn }) => {
  const eventsRef: any = useRef([]);

  const [allSoldTicketsFromSellers, setAllSoldTicketsFromSeller] = useState<
    Ticket[]
  >([]);

  const [allEvents, setAllEvents] = useState<EventInterface[]>([]);

  useEffect(() => {
    if (loggedIn) {
      setTimeout(() => {
        getData();
      }, 2000);
    }
  }, [loggedIn]);

  async function getData() {
    const sellerUid = auth.currentUser?.uid;

    if (sellerUid) {
      const eventsQuery = query(collection(db, "events"));

      const eventsQuerySnapshot = await getDocs(eventsQuery);
      const events: EventInterface[] = [];
      eventsQuerySnapshot.forEach(async (eventData: DocumentData) => {
        const event = eventData.data();
        events.push({
          eventId: eventData.id,
          name: event.name,
          location: event.location,
          price: event.price,
          commission: event.commission,
          date: event.date,
          time: event.time,
          active: event.active,
        });
      });

      console.log(events);

      eventsRef.current = events;
      setAllEvents(events);
      const allSoldTicketsArr: Ticket[] = [];

      for (let i = 0; i < events.length; i++) {
        const ticketsQuery = query(
          collection(db, "tickets", "events", events[i].eventId),
          where("uid", "==", sellerUid)
        );

        onSnapshot(ticketsQuery, (ticketsQuerySnapshot) => {
          //! DOUBLE TICKETS IN FINANCE
          ticketsQuerySnapshot.forEach((ticketData: DocumentData) => {
            const ticket: Ticket = ticketData.data();

            if (ticket.eventId === "PrSkhTD08tWe2PGfhUne") {
              console.log(ticket);
            }

            const allTicketsIndex = allSoldTicketsArr.findIndex(
              (soldTicket) =>
                soldTicket.eventId === ticket.eventId &&
                soldTicket.timestamp === ticket.timestamp
            );

            if (allTicketsIndex !== -1) {
              allSoldTicketsArr[allTicketsIndex] = {
                ...ticket,
                ticketId: ticketData.id,
              };
            } else {
              console.log(ticket);
              allSoldTicketsArr.push({
                ...ticket,
                ticketId: ticketData.id,
                price: events[i].price,
              });
              console.log(allSoldTicketsArr);
            }
          });
          if (i === events.length - 1) {
            console.log(allSoldTicketsArr);
            setAllSoldTicketsFromSeller(
              _.sortBy(allSoldTicketsArr, "timestamp").reverse()
            );
          }
        });
      }
    }
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header pageTtitle="FINANCE"></Header>
        {loggedIn ? (
          <FinanceComponent
            loggedIn={loggedIn}
            allSoldTickets={allSoldTicketsFromSellers}
            allEvents={allEvents}
          />
        ) : (
          <Redirect to="/auth" />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Finance;
