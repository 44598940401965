import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Auth.css";
import AuthComponent from "../components/AuthComponent";
import Header from "../components/Header";

interface AuthProps {
  loggedIn: boolean;
}

const Auth: React.FC<AuthProps> = ({ loggedIn }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Header pageTtitle="LOGIN"></Header>
        <AuthComponent loggedIn={loggedIn} />
      </IonContent>
    </IonPage>
  );
};

export default Auth;
