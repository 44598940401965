import React from "react";
import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

// import "./ExploreContainer.css";

interface ContainerProps {
  pageTtitle: string;
}

const Header: React.FC<ContainerProps> = ({ pageTtitle }) => {
  return (
    <IonHeader translucent={true}>
      <IonToolbar color="primary" className="ion-text-center">
        <IonTitle color="light" style={{ fontSize: "20px" }}>
          <b> {pageTtitle.toUpperCase()}</b>
        </IonTitle>
        {pageTtitle === "Admin Dashboard" && (
          <IonButtons slot="start">
            <IonMenuButton color="light"></IonMenuButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
