/* eslint-disable react/jsx-key */
import React, { RefObject, useEffect, useRef, useState } from "react";

import "./analytics.css";

import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonToggle,
} from "@ionic/react";

import {
  alertOutline,
  call,
  checkmark,
  checkmarkOutline,
  close,
  refresh,
  trashBin,
} from "ionicons/icons";

// FIREBASE
import { db } from "../../../index";
import {
  deleteDoc,
  doc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// HELPER
import _ from "underscore";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface FinanceComponentProps {
  loggedIn: boolean;
  allSoldTickets: Ticket[];
  allEvents: CreatedEvent[];
  allSeller: Seller[];
  getData(): Promise<void>;
}

export type Ticket = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  pax: number;
  boys: number;
  girls: number;
  seller: string;
  timestamp: number;
  uid: string;
  eventId: string;
  ticketId: string;
  paid: boolean;
  checkin: boolean;
  eventName: string;
  commission: number;
  eventDate: string;
  eventTime: string;
  commissionPaid: boolean;
  price: number;
  active: boolean;
};

export type CreatedEvent = {
  eventId: string;
  name: string;
  location: string;
  price: number;
  commission: number;
  date: string;
  time: string;
  active: boolean;
};

export type Seller = {
  displayName: string;
  email: string;
  password: string;
  sellerId: string;
};

const Analytics: React.FC<FinanceComponentProps> = ({
  loggedIn,
  allSoldTickets,
  allEvents,
  allSeller,
  getData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [onlyActiveEvents, setOnlyActiveEvents] = useState(false);
  const [soldOverview, setSoldOverview] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);
  const [isCheckin, setIsCheckin] = useState<boolean>(false);
  const [checkInData, setCheckInData] = useState<Ticket[]>([]);
  const [allSoldTicketsFromSellers, setAllSoldTicketsFromSeller] =
    useState<Ticket[]>(allSoldTickets);

  const [
    filteredAllSoldTicketsFromSellers,
    setFilteredallSoldTicketsFromSeller,
  ] = useState<Ticket[]>(allSoldTickets);

  const selectedSellerRef = useRef<string | null>(null);

  const selectedEventRef = useRef<string | null>(null);
  const searchValueRef = useRef<string | null>(null);

  const [items, setItems] = useState<Ticket[]>([]);

  const errorRef = useRef("");
  const successRef = useRef("");

  const widthRef = useRef<number>(window.innerWidth);

  const selectRef = useRef() as RefObject<HTMLIonSelectElement>;

  const eventSelectRef = useRef() as RefObject<HTMLIonSelectElement>;

  const counterRef = useRef(0);

  useEffect(() => {
    console.log("used");
    if (loggedIn) {
      console.log(selectedEventRef.current, selectedSellerRef.current);
      let filtered: Ticket[] = [...allSoldTickets];
      if (selectedSellerRef.current) {
        filtered = filtered.filter(
          (t) => t.seller === selectedSellerRef.current
        );
      }
      if (selectedEventRef.current) {
        filtered = filtered.filter(
          (t) => t.eventName === selectedEventRef.current
        );
      }
      generateItems(filtered, true);
      calculateStatisctics(filtered);
    } else {
      setFilteredallSoldTicketsFromSeller([]);
      setSoldOverview([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }, [allSoldTickets]);

  async function onChangeActiveEvents() {
    setOnlyActiveEvents(!onlyActiveEvents);
  }

  const generateItems = (tickets: Ticket[], reset: boolean) => {
    console.log(tickets);
    if (reset) {
      counterRef.current = 0;
    }

    const newItems: Ticket[] = [];
    for (
      let i = counterRef.current * 25;
      i < (counterRef.current + 1) * 25;
      i++
    ) {
      if (i < tickets.length) {
        newItems.push(tickets[i]);
      } else {
        displaySuccess("ALL DATA LOADED");
      }
    }

    counterRef.current += 1;

    if (!reset) {
      setItems([...items, ...newItems]);
    } else {
      setItems([...newItems]);
    }
  };

  async function refreshData() {
    getData();
    selectedSellerRef.current = null;
    selectedEventRef.current = null;
    searchValueRef.current = null;
    counterRef.current = 0;
    setFilteredallSoldTicketsFromSeller(allSoldTickets);
    // generateItems(allSoldTickets, true);
    // calculateStatisctics(allSoldTickets);
  }

  async function handleSearch(ev: Event, isCheckin: boolean) {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    searchValueRef.current = query;

    if (query) {
      const filteredTickets = filteredAllSoldTicketsFromSellers.filter(
        (t) =>
          t.name.toLowerCase().indexOf(query) > -1 ||
          t.surname.toLowerCase().indexOf(query) > -1 ||
          t.eventName.toLowerCase().indexOf(query) > -1 ||
          t.email.toLowerCase().indexOf(query) > -1 ||
          t.phone.toLowerCase().indexOf(query) > -1 ||
          t.pax.toString().toLowerCase().indexOf(query) > -1 ||
          t.girls.toString().toLowerCase().indexOf(query) > -1 ||
          t.boys.toString().toLowerCase().indexOf(query) > -1 ||
          t.seller.toString().toLowerCase().indexOf(query) > -1 ||
          t.paid.toString().toLowerCase().indexOf(query) > -1 ||
          t.eventDate
            .toString()
            .toLowerCase()
            .split("-")
            .reverse()
            .join(".")
            .indexOf(query) > -1 ||
          t.eventTime
            .toString()
            .toLowerCase()

            .indexOf(query) > -1
      );

      setFilteredallSoldTicketsFromSeller(filteredTickets);
      generateItems(filteredTickets, true);
      calculateStatisctics(filteredTickets);
      if (isCheckin) {
        setIsCheckin(true);
        setCheckInData(filteredTickets);
      }
    } else {
      setIsCheckin(false);
      let filtered: Ticket[] = allSoldTickets;

      console.log(allSoldTickets);

      if (selectedSellerRef.current || selectedEventRef.current) {
        if (selectedSellerRef.current) {
          filtered = allSoldTickets.filter(
            (f) => f.seller === selectedSellerRef.current
          );
        }

        if (selectedEventRef.current) {
          filtered = filtered.filter(
            (f) => f.eventName === selectedEventRef.current
          );
        }

        console.log(filtered);

        setFilteredallSoldTicketsFromSeller(filtered);
        generateItems(filtered, true);
        calculateStatisctics(filtered);
      } else {
        setFilteredallSoldTicketsFromSeller(allSoldTickets);
        generateItems(allSoldTickets, true);
        calculateStatisctics(allSoldTickets);
      }
    }
  }

  async function calculateStatisctics(tickets: Ticket[]) {
    let [
      totalGirls,
      totalBoys,
      dailyGirls,
      dailyBoys,
      dailyCommission,
      totalCommission,
      totalAmountToPay,
      collectedPayout,
      paidCommission,
    ] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (tickets.length > 0) {
      for (let i = 0; i < tickets.length; i++) {
        const event = allEvents.filter((e) => e.eventId === tickets[i].eventId);
        const soldToday =
          new Date(tickets[i].timestamp).getDate() +
            "/" +
            new Date(tickets[i].timestamp).getMonth() ===
          new Date(Date.now()).getDate() +
            "/" +
            new Date(Date.now()).getMonth();

        const eventCommission = event[0].commission;
        totalCommission += +(eventCommission * tickets[i].pax);
        totalGirls += tickets[i].girls;
        totalBoys += tickets[i].boys;
        if (soldToday) {
          dailyCommission += +(eventCommission * tickets[i].pax);
          dailyGirls += tickets[i].girls;
          dailyBoys += tickets[i].boys;
        }
        const eventPrice = event[0].price;

        collectedPayout += +(eventPrice * tickets[i].pax);
        if (!tickets[i].paid) {
          totalAmountToPay += +(eventPrice * tickets[i].pax);
        }

        if (tickets[i].commissionPaid) {
          paidCommission += +(eventCommission * tickets[i].pax);
        }

        if (i === tickets.length - 1) {
          setSoldOverview([
            totalBoys,
            totalGirls,
            dailyBoys,
            dailyGirls,
            dailyCommission,
            totalCommission,
            totalAmountToPay,
            collectedPayout,
            paidCommission,
          ]);
        }
      }
    } else {
      setSoldOverview([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }

  async function filterTicketsBySeller(seller: string) {
    selectedSellerRef.current = seller;

    let filtered: Ticket[];
    if (selectedSellerRef.current && selectedEventRef.current) {
      filtered = filteredAllSoldTicketsFromSellers.filter(
        (f) => f.seller === seller
      );
    } else if (selectedSellerRef.current) {
      filtered = allSoldTickets.filter((f) => f.seller === seller);
    } else {
      filtered = allSoldTickets;
    }

    setFilteredallSoldTicketsFromSeller(filtered);
    generateItems(filtered, true);
    calculateStatisctics(filtered);
  }

  async function filterTicketsByEvent(event: string) {
    console.log(allEvents);
    console.log("111111111");
    selectedEventRef.current = event;
    let filtered: Ticket[];
    if (selectedSellerRef.current && selectedEventRef.current) {
      filtered = filteredAllSoldTicketsFromSellers.filter(
        (f) => f.eventName === event
      );
    } else if (selectedEventRef.current) {
      filtered = allSoldTickets.filter((f) => f.eventName === event);
      console.log(filtered[0], "jjjjjjjjjjjjjjjjjjj");
    } else {
      filtered = allSoldTickets;
    }

    setFilteredallSoldTicketsFromSeller(filtered);
    generateItems(filtered, true);
    calculateStatisctics(filtered);
  }

  async function onManualCheckin(ticket: Ticket) {
    updateDoc(doc(db, "tickets", "events", ticket.eventId, ticket.ticketId), {
      checkin: !ticket.checkin as boolean,
    }).then(() => {
      // refreshData();
      if (isCheckin) {
        // refreshData();
        items.filter((t) =>
          t.ticketId === ticket.ticketId ? (t.checkin = !t.checkin) : t.checkin
        );
        // refreshData();

        // const changedItem: Ticket = items.filter(
        //   (t) => t.ticketId === ticket.ticketId
        // );

        // changedItem.checkin = !changedItem.checkin
        // // setItems(newItems);
        // allSoldTicketsFromSellers.forEach((t) =>
        //   t.ticketId === ticket.ticketId ? (t.checkin = !t.checkin) : t.checkin
        // );

        // setAllSoldTicketsFromSeller(allSoldTicketsFromSellers);
        searchValueRef.current = "";
        setCheckInData([]);
        setIsCheckin(false);
      } else {
        refreshData();
      }
    });
  }

  async function onDeleteTicket(ticket: Ticket) {
    deleteDoc(doc(db, "tickets", "events", ticket.eventId, ticket.ticketId))
      .then(async () => {
        setFilteredallSoldTicketsFromSeller(
          allSoldTicketsFromSellers.filter(
            (t) => t.ticketId !== ticket.ticketId
          )
        );

        const eventRef = doc(db, "events", ticket.eventId);
        await setDoc(
          eventRef,
          {
            pax: increment(-(+ticket.boys + +ticket.girls)),
            boys: increment(-+ticket.boys),
            girls: increment(-+ticket.girls),
          },
          { merge: true }
        );

        displaySuccess("Ticket deleted successfully");
        refreshData();
      })
      .catch((error) => {
        displayError({ message: error.message });
      });
  }

  async function displayError(error: { message: string }) {
    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function displaySuccess(successMessage: string) {
    successRef.current = successMessage;
    setIsOpenSuccess(true);
  }

  return widthRef.current > 1000 ? (
    <div>
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol sizeSm="3" offsetSm="1.5" size="4">
            <IonCard color="primary">
              <IonCardHeader className="ion-text-center">
                <IonCardTitle>
                  <b>MONEY</b>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent className="ion-text-center">
                <IonLabel key="payoutToCollect">
                  <b>PAYOUT TO COLLECT: {soldOverview[6]} €</b>
                </IonLabel>
                <br />
                <IonLabel key="collectedPayout">
                  <b>COLLECTED PAYOUT: {soldOverview[7] - soldOverview[6]} €</b>
                </IonLabel>
                <br />
                <IonLabel key="totalPayout">
                  <b>TOTAL PAYOUT: {soldOverview[7]} €</b>
                </IonLabel>
                <br />
              </IonCardContent>
            </IonCard>{" "}
          </IonCol>
          <IonCol sizeSm="3" size="4">
            <IonCard className="ion-text-center" color="primary">
              <IonCardHeader className="ion-text-center">
                <IonCardTitle>
                  <b>COMMISION</b>
                </IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <IonLabel key="commissionToPay">
                  <b>
                    COMMISSION TO PAY:{" "}
                    {soldOverview[5] - soldOverview[8]
                      ? soldOverview[5] - soldOverview[8] + " €"
                      : 0}
                  </b>
                </IonLabel>
                <br />

                <IonLabel key="paidCommission">
                  <b>PAID COMMISSION: {soldOverview[8]} €</b>
                </IonLabel>
                <br />

                <IonLabel key="totalCommission">
                  <b>TOTAL COMMISSION: {soldOverview[5]} €</b>
                </IonLabel>
              </IonCardContent>
            </IonCard>{" "}
          </IonCol>
          <IonCol sizeSm="3" size="4">
            <IonCard className="ion-text-center" color="primary">
              <IonCardHeader>
                <IonCardTitle>
                  <b>SOLD TICKETS</b>
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonLabel key="totalSold">
                  <b>TOTAL SOLD: {soldOverview[0] + soldOverview[1]}</b>
                </IonLabel>
                <br />

                <IonLabel key="genderSold">
                  <b>SOLD GIRLS: {soldOverview[1]} </b>
                  <b>BOYS: {soldOverview[0]} </b>
                </IonLabel>
                <br />

                <IonLabel key="toda<Sold">
                  <b>TODAY SOLD: {soldOverview[2] + soldOverview[3]}</b>
                </IonLabel>
              </IonCardContent>
            </IonCard>{" "}
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow>
            <IonCol size="8" offset="2">
              <IonSearchbar
                className="ion-no-padding"
                color="dark"
                key="searchbar"
                animated={true}
                placeholder="SEARCH HERE"
                debounce={500}
                onIonInput={(ev) => handleSearch(ev, false)}
                showClearButton="never"
                value={searchValueRef.current}
              ></IonSearchbar>
            </IonCol>
            <IonCol size="2">
              <IonIcon
                onClick={() => {
                  refreshData();
                }}
                icon={refresh}
                size="large"
                className="ion-padding-left"
                style={{ paddingTop: "3px", paddingLeft: "20px" }}
              ></IonIcon>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3" offset="2">
              <IonSelect
                class="mySelect"
                value={selectedSellerRef.current}
                ref={selectRef}
                aria-label="Seller"
                placeholder="SELECT SELLER"
                multiple={false}
                onIonChange={(e) => filterTicketsBySeller(e.detail.value)}
              >
                {allSeller.length > 0 &&
                  _.sortBy(allSeller, "displayName").map((seller) => {
                    return (
                      <IonSelectOption
                        value={seller.displayName}
                        key={seller.sellerId}
                      >
                        {seller.displayName}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonCol>
            <IonCol size="3">
              <IonToggle
                checked={onlyActiveEvents}
                className="ion-padding"
                color="dark"
                onIonChange={() => onChangeActiveEvents()}
              >
                {" "}
                ONLY ACTIVE EVENTS
              </IonToggle>
            </IonCol>
            <IonCol size="3" offset="1">
              <IonSelect
                class="mySelect"
                aria-label="Event"
                placeholder="SELECT EVENT"
                multiple={false}
                ref={eventSelectRef}
                value={selectedEventRef.current}
                onIonChange={(e) => filterTicketsByEvent(e.detail.value)}
              >
                {onlyActiveEvents &&
                  allEvents.length > 0 &&
                  _.sortBy(allEvents, "date")
                    .filter((e) => (e.active as boolean) === true)
                    .map((event) => {
                      return (
                        <IonSelectOption value={event.name} key={event.eventId}>
                          {event.date
                            .split("-")
                            .reverse()
                            .join(".")
                            .slice(0, 6)}{" "}
                          {event.name}
                        </IonSelectOption>
                      );
                    })}

                {!onlyActiveEvents &&
                  allEvents.length > 0 &&
                  _.sortBy(allEvents, "date").map((event) => {
                    return (
                      <IonSelectOption value={event.name} key={event.eventId}>
                        {event.date.split("-").reverse().join(".").slice(0, 6)}{" "}
                        {event.name}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonCol>
          </IonRow>
        </IonList>

        <IonList>
          {items.length > 0 &&
            items
              .filter((e) =>
                onlyActiveEvents
                  ? e.active === true
                  : e.active === true || e.active === false
              )
              .map((ticket, index) => {
                console.log(ticket.active);
                return (
                  <>
                    <IonRow key={ticket.ticketId}>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonIcon
                            slot="start"
                            icon={ticket.checkin ? checkmark : close}
                            color="light"
                            onClick={() => onManualCheckin(ticket)}
                          ></IonIcon>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {index} {ticket.eventName}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="3.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.surname} {ticket.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.pax}/{ticket.girls}{" "}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {+ticket.price * ticket.pax}/
                            {ticket.commission * ticket.pax}€
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>{ticket.seller}</IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.eventDate &&
                              ticket.eventDate
                                .split("-")
                                .reverse()
                                .join(".")}{" "}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      {/* <IonCol size="0.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonButton
                            id={ticket.ticketId}
                            fill="clear"
                            color="success"
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={trashBin}
                              color="light"
                            ></IonIcon>
                          </IonButton>
                        </IonItem>
                      </IonCol> */}
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonButton
                            id={ticket.ticketId}
                            fill="clear"
                            color="success"
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={trashBin}
                              color="light"
                            ></IonIcon>
                          </IonButton>
                          <CopyToClipboard
                            key={"clipboard"}
                            text={ticket.phone}
                            onCopy={() => console.log({ copied: true })}
                          >
                            <IonIcon
                              slot="end"
                              icon={call}
                              color="light"
                            ></IonIcon>
                          </CopyToClipboard>
                        </IonItem>
                      </IonCol>
                    </IonRow>

                    <IonAlert
                      trigger={ticket.ticketId}
                      header="You delete a ticket!"
                      message="Are you sure  to continue?"
                      buttons={[
                        {
                          text: "Cancel",
                          role: "cancel",
                        },
                        {
                          text: "YES",
                          role: "confirm",
                          handler: () => {
                            onDeleteTicket(ticket);
                          },
                        },
                      ]}
                    ></IonAlert>
                  </>
                );
              })}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            generateItems(filteredAllSoldTicketsFromSellers, false);
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonGrid>
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpen}
        message={"ERROR: " + errorRef.current}
        icon={alertOutline}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              setIsOpen(false);
            },
          },
        ]}
      ></IonToast>
      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpenSucess}
        message={successRef.current}
        duration={5000}
        icon={checkmarkOutline}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            handler: () => {
              setIsOpenSuccess(true);
            },
          },
        ]}
      ></IonToast>
    </div>
  ) : (
    <div className="container">
      <IonSearchbar
        className="ion-no-padding"
        color="dark"
        key="searchbar"
        animated={true}
        placeholder="SEARCH HERE"
        debounce={500}
        onIonInput={(ev) => handleSearch(ev, true)}
        showClearButton="never"
        value={searchValueRef.current}
      ></IonSearchbar>{" "}
      {isCheckin &&
        checkInData.map((item) => {
          // eslint-disable-next-line react/jsx-key
          return (
            <IonItem color={item.checkin ? "success" : "danger"}>
              <IonIcon
                slot="start"
                icon={item.checkin ? checkmark : close}
                color="dark"
                onClick={() => onManualCheckin(item)}
              ></IonIcon>
              NAME: {item.name} PAID: {item.price * item.pax}€ PAX: {item.pax}
            </IonItem>
          );
        })}
    </div>
  );
};

export default Analytics;
