import React, { useRef, useState } from "react";
import "./CreateSeller.css";
import { useForm, SubmitHandler } from "react-hook-form";

import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToast,
} from "@ionic/react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  User,
} from "firebase/auth";
import { alertOutline } from "ionicons/icons";
import { auth, db } from "../../..";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

interface CreateSellerComponent {
  loggedIn: boolean;
}

type Inputs = {
  displayName: string;
  email: string;
  password: string;
  sellerId?: string;
  type?: string;
};

const CreateSeller: React.FC<CreateSellerComponent> = ({ loggedIn }) => {
  console.log(loggedIn);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accountType, setAccount] = useState<"seller" | "qrSeller" | "admin">(
    "seller"
  );

  const errorRef = useRef("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => login(data);

  function capitalizeWords(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  async function login(data: Inputs) {
    console.log("LOGIN", data);

    data.displayName = capitalizeWords(data.displayName);

    const currentUser: User = auth.currentUser!;

    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (user) => {
        updateProfile(user.user, {
          displayName: data.displayName,
          photoURL: accountType,
        });

        const createdSeller = await addDoc(collection(db, "seller"), {
          ...data,
          timestamp: Date.now(),
          accountType: accountType,
        });

        await setDoc(doc(db, "allSeller", createdSeller.id), {
          ...data,
          timestamp: Date.now(),
          accountType: accountType,
        });
        await logout();

        const q = query(
          collection(db, "seller"),
          where("email", "==", currentUser.email!)
        );

        const userData = await getDocs(q);

        await signInWithEmailAndPassword(
          auth,
          currentUser.email!,
          userData.docs[0].data().password
        );

        reset(
          {
            displayName: "",
            email: "",
            password: "",
          },
          {
            keepErrors: true,
            keepDirty: true,
          }
        );

        setAccountType(null);
      })

      .catch((error) => {
        displayError(error);
      });
  }

  async function setAccountType(accountType: any) {
    console.log(accountType);

    setValue("type", accountType);
    setAccount(accountType);
  }

  async function logout() {
    await signOut(auth).catch((error) => {
      displayError(error);
    });
  }

  async function displayError(error: { message: string }) {
    console.log(error);
    errorRef.current = error.message;
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, 15000);
  }
  return (
    <div className="container">
      <h2 className="ion-text-center">
        <b>CREATE SELLER</b>
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="4" offsetSm="4">
              <IonList>
                <IonItem lines="full">
                  <IonInput
                    {...register("displayName", { required: true })}
                    label="Username"
                    type="text"
                    placeholder="Please enter the username"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.email && <span>This field is required</span>}
                <IonItem lines="full">
                  <IonInput
                    {...register("email", { required: true })}
                    label="E-Mail"
                    type="email"
                    placeholder="Please enter your E-Mail"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.email && <span>This field is required</span>}

                <IonItem lines="full">
                  <IonInput
                    {...register("password", { required: true })}
                    label="Password"
                    type="password"
                    placeholder="Please enter your password"
                    labelPlacement="floating"
                  ></IonInput>
                </IonItem>
                {errors.password && (
                  <span>This field is required {errors.password.message}</span>
                )}
                <div style={{ paddingTop: "2%" }}>
                  <IonRadioGroup
                    {...register("type", { required: true })}
                    value={accountType}
                    onIonChange={(e) => setAccountType(e.target.value)}
                  >
                    <IonRadio
                      value="seller"
                      aria-label="Custom checkbox that is checked"
                    >
                      Seller
                    </IonRadio>
                    <IonRadio value="qrSeller" aria-label="Custom checkbox">
                      QR-Seller
                    </IonRadio>

                    <IonRadio value="admin" aria-label="Custom checkbox">
                      Admin
                    </IonRadio>
                  </IonRadioGroup>
                </div>

                <IonButton
                  type="submit"
                  color="dark"
                  expand="block"
                  size="small"
                  style={{ width: "50%", margin: "auto" }}
                  disabled={!isValid}
                >
                  CREATE SELLER
                </IonButton>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>

      <IonToast
        style={{ textAlign: "center" }}
        isOpen={isOpen}
        message={"ERROR: " + errorRef.current}
        duration={5000}
        icon={alertOutline}
      ></IonToast>
    </div>
  );
};

export default CreateSeller;
