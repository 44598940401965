import React, { useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "./ExploreContainer";
import "./ExploreContainer.css";

import { QrReader, OnResultFunction } from "react-qr-reader";
import { DocumentData, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "..";
import {
  beer,
  calendar,
  people,
  ticketOutline,
  ticketSharp,
  wine,
} from "ionicons/icons";

interface QRScannerProps {
  loggedIn: boolean;
}

export interface TicketInterface {
  ticketId: string;
  name: string;
  description: string;
  drinks: string;
  price: number;
  commission: number;
  date: Date;
  time: string;
  location: string;
  capacity: number;
  eventId: string;
  pax?: number;
  boys?: number;
  girls?: number;
  uid: string;
  timestamp: number;
  surname: string;
  seller: string;
  phone: string;
  paid: boolean;
  eventTime: string;
  duration: number;
  eventDate: string;
  eventName: string;
  checkin: boolean;
}
const QRScannerComponent: React.FC<QRScannerProps> = ({ loggedIn }) => {
  const [data, setData] = useState("No result");
  const [ticket, setTicket] = useState<TicketInterface | null>(null);
  const [startScan, setStartScan] = useState(false);

  const scanCounterRef = useRef<number>(0);

  // getTicket("0F2OdfP3nYhs8JHOwqzJ", "0ILvZdEY9OXf92VI0zfU");

  async function getTicket(eventId: string, ticketId: string) {
    console.log(eventId, ticketId);

    scanCounterRef.current += 1;

    const ticket: DocumentData = await getDoc(
      doc(db, "tickets", "events", eventId, ticketId)
    );

    setTicket({ ...ticket.data(), ticketId: ticket.id });
  }

  async function onCheckIn(eventId: string, ticketId: string) {
    await updateDoc(doc(db, "tickets", "events", eventId, ticketId), {
      checkin: true,
    });
    setData("Waiting for scan");
    setTicket(null);

    // if (scanCounterRef.current >= 10) {
    //   ("hahahhaha");

    //   setStartScan(false);
    //   setTimeout(() => {
    //     setStartScan(true);
    //     scanCounterRef.current = 0;
    //   }, 1000);
    // }
  }

  async function onNext() {
    setData("Waiting for scan");
    setTicket(null);

    // if (scanCounterRef.current >= 10) {
    //   console.log("hahahhaha");
    //   setStartScan(false);
    //   setTimeout(() => {
    //     setStartScan(true);
    //     scanCounterRef.current = 0;
    //   }, 1000);
    // }
  }

  return (
    <>
      <div style={{ marginBottom: "10%", marginTop: "-50px" }}>
        {startScan && (
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol sizeSm="4" offsetSm="4">
                <QrReader
                  scanDelay={5000}
                  onResult={(result: any, error: any) => {
                    if (result) {
                      console.log(result?.text);
                      // setData(result?.text);
                      // setData(
                      //   "eventId: 3476qpg20vY9xrDJ5iLX ticketId: 7rxTH8HLRIQNu1vj7EOJ"
                      // );

                      getTicket(
                        result?.text.split("__")[0],
                        result?.text.split("__")[1]
                      );

                      //! eventId
                    }

                    // if (error) {
                    //   console.log(error);
                    // }
                  }}
                  constraints={{ facingMode: "environment" }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </div>
      <IonGrid>
        <div className="container">
          <IonRow
            className="ion-padding ion-margin"
            style={{ marginTop: "50%" }}
          >
            <IonCol sizeSm="4" offsetSm="4">
              {!startScan && (
                <IonButton onClick={() => setStartScan(true)} color="dark">
                  START SCAN
                </IonButton>
              )}
              {startScan && (
                <IonButton onClick={() => setStartScan(false)} color="dark">
                  STOP SCAN
                </IonButton>
              )}{" "}
            </IonCol>
          </IonRow>
        </div>

        {ticket && startScan && (
          <>
            <IonRow class="ion-text-center">
              <IonCol size="10" offset="1">
                <IonItem lines="none" className="ion-text-center">
                  <IonIcon
                    slot="end"
                    icon={ticketSharp}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                  <IonLabel style={{ fontWeight: "bold" }}>
                    {ticket?.eventName}{" "}
                  </IonLabel>
                  <IonIcon
                    slot="start"
                    icon={ticketSharp}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-text-center">
              <IonCol size="10" offset="1">
                <IonItem lines="none" className="ion-text-center">
                  <IonIcon
                    slot="end"
                    icon={calendar}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                  <IonLabel style={{ fontWeight: "bold" }}>
                    {ticket?.eventDate.split("-").reverse().join(".")}{" "}
                  </IonLabel>
                  <IonIcon
                    slot="start"
                    icon={calendar}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="ion-text-center">
              <IonCol size="10" offset="1">
                <IonItem lines="none" className="ion-text-center">
                  <IonIcon
                    slot="end"
                    icon={people}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                  <IonLabel style={{ fontWeight: "bold" }}>
                    {ticket?.pax}
                    {" /"} {ticket?.girls}
                  </IonLabel>
                  <IonIcon
                    slot="start"
                    icon={people}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow class="ion-text-center">
              <IonCol size="10" offset="1">
                <IonItem lines="none" className="ion-text-center">
                  <IonIcon slot="end" icon={wine} size="large" color="primary">
                    {" "}
                  </IonIcon>{" "}
                  <IonLabel style={{ fontWeight: "bold" }}>
                    {ticket?.drinks}{" "}
                  </IonLabel>
                  <IonIcon
                    slot="start"
                    icon={beer}
                    size="large"
                    color="primary"
                  ></IonIcon>{" "}
                </IonItem>
              </IonCol>
            </IonRow>

            {!ticket.checkin && (
              <IonRow>
                <IonCol size="4" offset="2">
                  {" "}
                  <>
                    <IonButton
                      disabled={ticket?.checkin}
                      color={ticket?.checkin ? "success" : "danger"}
                      onClick={() => onCheckIn(ticket.eventId, ticket.ticketId)}
                    >
                      CHECKIN
                    </IonButton>
                  </>
                </IonCol>
                <IonCol size="4" offset="1">
                  <IonButton
                    style={{ float: "left" }}
                    color="dark"
                    onClick={() => onNext()}
                  >
                    CANCEL
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
            {ticket.checkin && (
              <IonRow className="ion-text-center">
                <IonCol size="8" offset="2">
                  <IonButton color="success" onClick={() => onNext()}>
                    ALREADY CHECKED IN
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
          </>
        )}
      </IonGrid>
    </>
  );
};

export default QRScannerComponent;
