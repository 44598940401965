import React, { RefObject, useEffect, useRef, useState } from "react";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast,
} from "@ionic/react";

import {
  alertOutline,
  checkmark,
  checkmarkOutline,
  close,
  download,
  refresh,
  walletOutline,
} from "ionicons/icons";

// FIREBASE
import { db } from "../../../index";
import { Unsubscribe, doc, updateDoc } from "firebase/firestore";

// HELPER
import _ from "underscore";

import { EventInterface } from "../../../pages/Admin";

interface FinanceComponentProps {
  loggedIn: boolean;
  allEvents: EventInterface[];
  allSeller: Seller[];
  allSoldTickets: Ticket[];
  getData(): Promise<void>;
}

type Ticket = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  pax: number;
  boys: number;
  girls: number;
  seller: string;
  timestamp: number;
  uid: string;
  eventId: string;
  ticketId: string;
  paid: boolean;
  checkin: boolean;
  eventName: string;
  commission: number;
  eventDate: string;
  eventTime: string;
  price: number;
  commissionPaid: boolean;
};

type CreatedEvent = {
  eventId: string;
  name: string;
  location: string;
  price: number;
  commission: number;
  date: string;
  time: string;
};

type Seller = {
  displayName: string;
  email: string;
  password: string;
  sellerId: string;
};

const Payouts: React.FC<FinanceComponentProps> = ({
  loggedIn,
  allEvents,
  allSeller,
  allSoldTickets,
  getData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [items, setItems] = useState<Ticket[]>([]);

  const [soldOverview, setSoldOverview] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);

  const [
    filteredAllSoldTicketsFromSellers,
    setFilteredallSoldTicketsFromSeller,
  ] = useState<Ticket[]>([]);

  const errorRef = useRef("");
  const successRef = useRef("");

  const counterRef = useRef(0);

  const selectRef = useRef() as RefObject<HTMLIonSelectElement>;

  const selectedSellerRef = useRef<string | null>(null);

  const widthRef = useRef<number>(window.innerWidth);

  let unsubscribe: Unsubscribe;

  useEffect(() => {
    console.log("USEEFFEKT");
    if (loggedIn) {
      let filtered: Ticket[];
      if (selectedSellerRef.current) {
        filtered = allSoldTickets.filter(
          (t) => t.seller === selectedSellerRef.current
        );
      } else {
        filtered = allSoldTickets;
      }

      setFilteredallSoldTicketsFromSeller(filtered);
      generateItems(filtered, true);
      calculateStatisctics(filtered, allEvents);
    } else {
      setFilteredallSoldTicketsFromSeller([]);
      setSoldOverview([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }, [allSoldTickets]);

  const generateItems = (tickets: Ticket[], reset: boolean) => {
    if (reset) {
      counterRef.current = 0;
    }

    if (selectedSellerRef.current) {
      tickets = tickets.filter((t) => t.seller === selectedSellerRef.current);
    }

    const newItems: Ticket[] = [];
    for (
      let i = counterRef.current * 25;
      i < (counterRef.current + 1) * 25;
      i++
    ) {
      if (i < tickets.length) {
        newItems.push(tickets[i]);
      } else {
        displaySuccess("ALL DATA LOADED");
      }
    }
    counterRef.current += 1;

    if (!reset) {
      setItems([...items, ...newItems]);
    } else {
      setItems([...newItems]);
    }
  };

  async function refreshData(reset?: boolean) {
    if (!reset) {
      getData();
      counterRef.current = 0;

      if (selectedSellerRef.current) {
        let tickets: Ticket[] = [...allSoldTickets];
        tickets = allSoldTickets.filter(
          (t) => t.seller === selectedSellerRef.current
        );

        console.log("hahahha");
        setFilteredallSoldTicketsFromSeller(tickets);
        generateItems(tickets, true);
        calculateStatisctics(tickets, allEvents);
      } else {
        selectedSellerRef.current = null;
        setFilteredallSoldTicketsFromSeller(allSoldTickets);
        generateItems(allSoldTickets, true);
        calculateStatisctics(allSoldTickets, allEvents);
      }
    } else {
      selectedSellerRef.current = null;
      setFilteredallSoldTicketsFromSeller(allSoldTickets);
      generateItems(allSoldTickets, true);
      calculateStatisctics(allSoldTickets, allEvents);
    }
  }

  async function filterTicketsBySeller(seller: string) {
    let filtered: Ticket[];
    if (seller) {
      filtered = allSoldTickets.filter((f) => f.seller === seller);
    } else {
      filtered = allSoldTickets;
    }
    selectedSellerRef.current = seller;

    console.log(filtered);

    setFilteredallSoldTicketsFromSeller(filtered);
    generateItems(filtered, true);
    calculateStatisctics(filtered, allEvents);
  }

  async function calculateStatisctics(
    allSoldTicketsArr: Ticket[],
    events: CreatedEvent[]
  ) {
    let [
      totalGirls,
      totalBoys,
      dailyGirls,
      dailyBoys,
      dailyCommission,
      totalCommission,
      totalAmountToPay,
      collectedPayout,
      paidCommission,
    ] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (allSoldTicketsArr.length > 0) {
      for (let i = 0; i < allSoldTicketsArr.length; i++) {
        const event = events.filter(
          (e) => e.eventId === allSoldTicketsArr[i].eventId
        );
        const soldToday =
          new Date(allSoldTicketsArr[i].timestamp).getDate() +
            "/" +
            new Date(allSoldTicketsArr[i].timestamp).getMonth() ===
          new Date(Date.now()).getDate() +
            "/" +
            new Date(Date.now()).getMonth();

        // {ticket.price * ticket.pax}/
        //                 {ticket.commission * ticket.pax}€

        const eventCommission = event[0].commission;
        totalCommission += +(eventCommission * allSoldTicketsArr[i].pax);
        totalGirls += allSoldTicketsArr[i].girls;
        totalBoys += allSoldTicketsArr[i].boys;
        if (soldToday) {
          dailyCommission += +(eventCommission * allSoldTicketsArr[i].pax);
          dailyGirls += allSoldTicketsArr[i].girls;
          dailyBoys += allSoldTicketsArr[i].boys;
        }
        // const eventPrice = event[0].price;
        const eventPrice =
          allSoldTicketsArr[i].seller === "Shop 1" ||
          allSoldTicketsArr[i].seller === "Shop 2"
            ? allSoldTicketsArr[i].price
            : event[0].price;

        collectedPayout += +(eventPrice * allSoldTicketsArr[i].pax);
        if (!allSoldTicketsArr[i].paid) {
          totalAmountToPay += +(eventPrice * allSoldTicketsArr[i].pax);
        }

        if (allSoldTicketsArr[i].commissionPaid) {
          paidCommission += +(eventCommission * allSoldTicketsArr[i].pax);
        }

        if (i === allSoldTicketsArr.length - 1) {
          setSoldOverview([
            totalBoys,
            totalGirls,
            dailyBoys,
            dailyGirls,
            dailyCommission,
            totalCommission,
            totalAmountToPay,
            collectedPayout,
            paidCommission,
          ]);
        }
      }
    } else {
      setSoldOverview([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    }
  }

  async function onSinglePay(ticket: Ticket) {
    await updateDoc(
      doc(db, "tickets", "events", ticket.eventId, ticket.ticketId),
      {
        paid: true,
      }
    )
      .then(() => {
        displaySuccess("Ticket successfully marked as paid");
        refreshData();
      })
      .catch((error) => {
        displayError(error);
      });
  }

  async function onSingleCommissionPay(ticket: Ticket) {
    await updateDoc(
      doc(db, "tickets", "events", ticket.eventId, ticket.ticketId),
      {
        commissionPaid: true,
      }
    )
      .then(() => {
        displaySuccess("Ticket successfully marked as commission paid");
        refreshData();
      })
      .catch((error) => {
        displayError(error);
      });
  }

  async function onCollectAll() {
    const ticketsToPay: Ticket[] = filteredAllSoldTicketsFromSellers.filter(
      (t) => !t.paid
    );

    const selectedSelelrs: string[] = [];

    for (const ticket of ticketsToPay) {
      if (!selectedSelelrs.includes(ticket.seller)) {
        selectedSelelrs.push(ticket.seller);
      }
    }

    if (selectedSelelrs.length === 1) {
      ticketsToPay.forEach((ticketToPay) => {
        updateDoc(
          doc(
            db,
            "tickets",
            "events",
            ticketToPay.eventId,
            ticketToPay.ticketId
          ),
          {
            paid: true,
          }
        )
          .then(() => {
            displaySuccess(
              ticketsToPay.length +
                " Ticket(s) successfully marked as commission"
            );

            refreshData();
          })
          .catch((error) => {
            displayError(error);
          });
      });
    } else {
      displayError({
        message: "You selected more than 1 seller",
      });
    }
  }

  async function onPayAllCommission() {
    const ticketsToPay: Ticket[] = filteredAllSoldTicketsFromSellers.filter(
      (t) => !t.commissionPaid
    );

    const selectedSelelrs: string[] = [];

    for (const ticket of ticketsToPay) {
      if (!selectedSelelrs.includes(ticket.seller)) {
        selectedSelelrs.push(ticket.seller);
      }
    }

    if (selectedSelelrs.length === 1) {
      ticketsToPay.forEach((ticketToPay) => {
        updateDoc(
          doc(
            db,
            "tickets",
            "events",
            ticketToPay.eventId,
            ticketToPay.ticketId
          ),
          {
            commissionPaid: true,
          }
        )
          .then(() => {
            displaySuccess(
              ticketsToPay.length +
                " Ticket(s) successfully marked as commission paid"
            );

            refreshData();
          })
          .catch((error) => {
            displayError(error);
          });
      });
    } else {
      displayError({
        message: "You selected more than 1 seller",
      });
    }
  }

  async function displayError(error: { message: string }) {
    errorRef.current = error.message;
    setIsOpen(true);
  }

  async function displaySuccess(successMessage: string) {
    successRef.current = successMessage;
    setIsOpenSuccess(true);
  }

  async function onManualCheckin(ticket: Ticket) {
    await updateDoc(
      doc(db, "tickets", "events", ticket.eventId, ticket.ticketId),
      {
        checkin: !ticket.checkin as boolean,
      }
    );

    refreshData();
  }

  async function onUndoCommission(ticket: Ticket) {
    ticket.commissionPaid;

    await updateDoc(
      doc(db, "tickets", "events", ticket.eventId, ticket.ticketId),
      {
        commissionPaid: !ticket.commissionPaid as boolean,
      }
    );

    displaySuccess("UNDO COMMISSION PAYMENT SUCCESSFULL");

    refreshData();
  }

  async function onUndoMoneyCollected(ticket: Ticket) {
    await updateDoc(
      doc(db, "tickets", "events", ticket.eventId, ticket.ticketId),
      {
        paid: !ticket.paid as boolean,
      }
    );
    refreshData();
  }

  return (
    <div>
      {widthRef.current >= 1000 && (
        <>
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol sizeSm="3" offsetSm="1.5" size="4">
                <IonCard color="primary">
                  <IonCardHeader className="ion-text-center">
                    <IonCardTitle>
                      <b>MONEY</b>
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent className="ion-text-center">
                    <IonLabel key="payoutToCollect">
                      <b>PAYOUT TO COLLECT: {soldOverview[6]} €</b>
                    </IonLabel>
                    <br />
                    <IonLabel key="collectedPayout">
                      <b>
                        COLLECTED PAYOUT: {soldOverview[7] - soldOverview[6]} €
                      </b>
                    </IonLabel>
                    <br />
                    <IonLabel key="totalPayout">
                      <b>TOTAL PAYOUT: {soldOverview[7]} €</b>
                    </IonLabel>
                    <br />
                  </IonCardContent>
                </IonCard>{" "}
              </IonCol>
              <IonCol sizeSm="3" size="4">
                <IonCard className="ion-text-center" color="primary">
                  <IonCardHeader className="ion-text-center">
                    <IonCardTitle>
                      <b>COMMISION</b>
                    </IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    <IonLabel key="commissionToPay">
                      <b>
                        COMMISSION TO PAY: {soldOverview[5] - soldOverview[8]} €
                      </b>
                    </IonLabel>
                    <br />

                    <IonLabel key="paidCommission">
                      <b>PAID COMMISSION: {soldOverview[8]} €</b>
                    </IonLabel>
                    <br />

                    <IonLabel key="totalCommission">
                      <b>TOTAL COMMISSION: {soldOverview[5]} €</b>
                    </IonLabel>
                  </IonCardContent>
                </IonCard>{" "}
              </IonCol>
              <IonCol sizeSm="3" size="4">
                <IonCard className="ion-text-center" color="primary">
                  <IonCardHeader>
                    <IonCardTitle>
                      <b>SOLD TICKETS</b>
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonLabel key="totalSold">
                      <b>TOTAL SOLD: {soldOverview[0] + soldOverview[1]}</b>
                    </IonLabel>
                    <br />

                    <IonLabel key="genderSold">
                      <b>SOLD GIRLS: {soldOverview[1]} </b>
                      <b>BOYS: {soldOverview[0]} </b>
                    </IonLabel>
                    <br />

                    <IonLabel key="toda<Sold">
                      <b>TODAY SOLD: {soldOverview[2] + soldOverview[3]}</b>
                    </IonLabel>
                  </IonCardContent>
                </IonCard>{" "}
              </IonCol>
            </IonRow>
            <IonList>
              <IonRow className="ion-no-padding" color="light">
                <IonCol size="3" offset="1.5">
                  <IonButton
                    fill="outline"
                    color="dark"
                    expand="block"
                    size="default"
                    onClick={() => {
                      onCollectAll();
                    }}
                  >
                    MONEY COLLECTED
                    <IonIcon
                      icon={walletOutline}
                      color="dark"
                      className="ion-padding"
                    ></IonIcon>
                  </IonButton>
                </IonCol>
                {/* <IonCol size="1" offset="0.5">
                  <IonButtons className="ion-padding-left">
                    <IonButton
                      onClick={() =>
                        generateItems(filteredAllSoldTicketsFromSellers, true)
                      }
                    >
                      <IonIcon
                        icon={cloudDownloadOutline}
                        slot="icon-only"
                      ></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonCol> */}

                <IonCol size="2" offset="1">
                  <IonSelect
                    value={selectedSellerRef.current}
                    color="success"
                    ref={selectRef}
                    aria-label="Seller"
                    placeholder="SELECT SELLER"
                    multiple={false}
                    // onIonCancel={() => refreshData()}
                    onIonChange={(e) => filterTicketsBySeller(e.detail.value)}
                  >
                    {allSeller.length > 0 &&
                      _.sortBy(allSeller, "displayName").map((seller) => {
                        return (
                          <IonSelectOption
                            value={seller.displayName}
                            key={seller.sellerId}
                          >
                            {seller.displayName.toUpperCase()}
                          </IonSelectOption>
                        );
                      })}
                  </IonSelect>
                </IonCol>
                <IonCol size="3">
                  <IonButton
                    fill="outline"
                    color="dark"
                    expand="block"
                    size="default"
                    onClick={() => {
                      onPayAllCommission();
                    }}
                  >
                    PAY COMMISSION
                    <IonIcon
                      icon={walletOutline}
                      color="dark"
                      className="ion-padding"
                    ></IonIcon>
                  </IonButton>
                </IonCol>
                <IonCol size="1">
                  <IonButtons>
                    <IonButton onClick={() => refreshData(true)}>
                      <IonIcon icon={refresh} slot="icon-only"></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonList>
            <IonList>
              {items.length > 0 &&
                items.map((ticket, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <IonRow key={ticket.ticketId}>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonIcon
                            slot="start"
                            icon={ticket.checkin ? checkmark : close}
                            color="light"
                            onClick={() => onManualCheckin(ticket)}
                          ></IonIcon>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {index} {ticket.eventName}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.surname} {ticket.name}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.pax}/{ticket.girls}{" "}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.price * ticket.pax}/
                            {ticket.commission * ticket.pax}€
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>{ticket.seller}</IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1.5">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          <IonLabel>
                            {ticket.eventDate &&
                              ticket.eventDate
                                .split("-")
                                .reverse()
                                .join(".")}{" "}
                          </IonLabel>
                        </IonItem>
                      </IonCol>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          {!ticket.paid && (
                            <IonIcon
                              onClick={() => {
                                onSinglePay(ticket);
                              }}
                              icon={walletOutline}
                              color="light"
                              slot="end"
                            ></IonIcon>
                          )}
                          {ticket.paid && (
                            <IonIcon
                              icon={checkmark}
                              color="light"
                              slot="end"
                              onClick={() => onUndoMoneyCollected(ticket)}
                            ></IonIcon>
                          )}
                        </IonItem>
                      </IonCol>
                      <IonCol size="1">
                        <IonItem
                          color={ticket.paid ? "success" : "danger"}
                          lines="none"
                        >
                          {!ticket.commissionPaid && (
                            <IonIcon
                              onClick={() => {
                                onSingleCommissionPay(ticket);
                              }}
                              icon={download}
                              color="light"
                              slot="end"
                            ></IonIcon>
                          )}
                          {ticket.commissionPaid && (
                            <IonIcon
                              icon={checkmark}
                              color="light"
                              slot="end"
                              onClick={() => onUndoCommission(ticket)}
                            ></IonIcon>
                          )}
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  );
                })}
            </IonList>
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                generateItems(allSoldTickets, false);
                setTimeout(() => ev.target.complete(), 500);
              }}
            >
              <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonGrid>
          <IonToast
            style={{ textAlign: "center" }}
            isOpen={isOpen}
            message={"ERROR: " + errorRef.current}
            icon={alertOutline}
            buttons={[
              {
                text: "OK",
                role: "cancel",
                handler: () => {
                  setIsOpen(false);
                },
              },
            ]}
          ></IonToast>
          <IonToast
            style={{ textAlign: "center" }}
            isOpen={isOpenSucess}
            message={successRef.current}
            duration={5000}
            icon={checkmarkOutline}
            buttons={[
              {
                text: "OK",
                role: "cancel",
                handler: () => {
                  setIsOpenSuccess(false);
                },
              },
            ]}
          ></IonToast>
        </>
      )}

      {widthRef.current < 1000 && (
        <div className="container">
          <h1> Please use a Laptop or PC</h1>
        </div>
      )}
    </div>
  );
};

export default Payouts;
