import React, { useEffect, useRef, useState } from "react";
import "./CreateEvents.css";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";

import { alertOutline, checkmarkOutline, create, trash } from "ionicons/icons";
import { db } from "../../..";
import {
  DocumentData,
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import CreateEvents from "./CreateEvents";
import _ from "underscore";

interface CreateSellerComponent {
  loggedIn: boolean;
}

type Inputs = {
  name: string;
  active: boolean;
  description: string;
  drinks: string;
  price: number;
  commission: number;
  date: Date;
  time: string;
  location: string;
  capacity: number;
  eventId: string;
  duration: number;
  pax: number;
  girls: number;
};

const AllEvents: React.FC<CreateSellerComponent> = ({ loggedIn }) => {
  console.log(loggedIn);

  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isOpenSucess, setIsOpenSuccess] = useState<boolean>(false);
  // const [segment, setSegment] = useState<any>("allEvents");
  const [allEvents, setAllEvents] = useState<Inputs[]>([]);

  const errorRef = useRef("");
  const successRef = useRef("");

  const eventRef = useRef<Inputs | null>(null);

  const widthRef = useRef<number>(window.innerWidth);

  useEffect(() => {
    getAllEvents();

    // return unsubscribe();
  }, []);

  async function getAllEvents() {
    const q = query(collection(db, "events"), where("active", "==", true));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const events: Inputs[] = [];
      querySnapshot.forEach((doc: DocumentData) => {
        events.push({ ...doc.data(), eventId: doc.id });
      });
      setAllEvents(events);
    });

    return unsubscribe;
  }

  async function deleteEvent(eventId: string) {
    console.log(eventId);

    const eventRef = doc(db, "events", eventId);

    // Set the "capital" field of the city 'DC'
    await updateDoc(eventRef, {
      active: false,
    });

    displaySuccess("Event deleted successfully");
  }

  async function onEditEvent(event: Inputs) {
    console.log(111);

    eventRef.current = event;
    setOpenEditModal(true);
  }

  // async function displayError(error: { message: string }) {
  //   console.log(error);
  //   errorRef.current = error.message;
  //   setIsOpen(true);
  // }

  async function displaySuccess(successMessage: string) {
    console.log("MESSAGEE");
    successRef.current = successMessage;
    setIsOpenSuccess(true);

    setTimeout(() => {
      setIsOpenSuccess(false);
    }, 5000);
  }

  return widthRef.current > 1000 ? (
    <>
      <div>
        <div className="container">
          <IonGrid className="ion-no-padding">
            <IonList>
              <h2 className="ion-text-center">
                <b>ALL ACTIVE EVENTS</b>
              </h2>
              <IonLabel className="ion-text-center" color="danger">
                Delete Event only if you received all the money
              </IonLabel>
              <IonRow style={{ border: "1px solid" }}>
                <IonCol size="1.5" sizeSm="2">
                  <IonLabel style={{ fontWeight: "bold" }}>EVENT</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                  <IonLabel style={{ fontWeight: "bold" }}>
                    PAX / GIRLS
                  </IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                  <IonLabel style={{ fontWeight: "bold" }}>CAPACITY</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                  <IonLabel style={{ fontWeight: "bold" }}>
                    PRICE / COM
                  </IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                  <IonLabel style={{ fontWeight: "bold" }}>DATE</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1">
                  <IonLabel style={{ fontWeight: "bold" }}>TIME</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1">
                  <IonLabel style={{ fontWeight: "bold" }}>DRINKS</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1">
                  <IonLabel style={{ fontWeight: "bold" }}>EDIT</IonLabel>
                </IonCol>
                <IonCol size="1.5" sizeSm="1">
                  <IonLabel style={{ fontWeight: "bold" }}>DELETE</IonLabel>
                </IonCol>
              </IonRow>
              <div style={{ marginTop: allEvents.length * 20 + "px" }}>
                {_.sortBy(allEvents, "date").map((event) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <IonRow key={event.name} style={{ border: "1px solid" }}>
                      <IonCol size="1.5" sizeSm="2">
                        <IonLabel>{event.name}</IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                        <IonLabel>
                          {event.pax
                            ? event.pax.toString() +
                              "/" +
                              event.girls.toString()
                            : "0/0"}
                        </IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                        <IonLabel>{event.capacity}</IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                        <IonLabel>
                          {event.price + "/" + event.commission}{" "}
                        </IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1" offsetSm="0.5">
                        <IonLabel>
                          {event.date.toString().split("-").reverse().join(".")}{" "}
                        </IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1">
                        <IonLabel>{event.time}</IonLabel>
                      </IonCol>
                      <IonCol size="1.5" sizeSm="1">
                        <IonLabel>{event.drinks}</IonLabel>
                      </IonCol>
                      <IonCol size="1" sizeSm="1">
                        <IonIcon
                          icon={create}
                          onClick={() => onEditEvent(event)}
                        ></IonIcon>{" "}
                      </IonCol>
                      <IonCol size="1" sizeSm="1">
                        <IonIcon
                          icon={trash}
                          onClick={() => deleteEvent(event.eventId)}
                        ></IonIcon>{" "}
                      </IonCol>
                    </IonRow>
                  );
                })}
              </div>
            </IonList>
          </IonGrid>
        </div>
        {errorRef.current.length > 0 && (
          <IonToast
            style={{ textAlign: "center" }}
            isOpen={openEditModal}
            message={"ERROR: " + errorRef.current}
            icon={alertOutline}
            buttons={[
              {
                text: "OK",
                role: "cancel",
                handler: () => {
                  setIsOpen(false);
                },
              },
            ]}
          ></IonToast>
        )}
        <IonToast
          style={{ textAlign: "center" }}
          isOpen={isOpenSucess}
          message={successRef.current}
          duration={5000}
          icon={checkmarkOutline}
        ></IonToast>
      </div>

      <IonModal
        isOpen={openEditModal}
        onDidDismiss={() => setOpenEditModal(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Modal</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setOpenEditModal(false)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <CreateEvents
            loggedIn={loggedIn}
            event={eventRef.current}
            setOpenEditModal={setOpenEditModal}
          />
        </IonContent>
      </IonModal>
    </>
  ) : (
    <div className="container">
      <h1> Please use a Laptop or PC</h1>
    </div>
  );
};

export default AllEvents;
