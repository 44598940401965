import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./Ticket.css";
import TicketComponent from "../components/TicketComponent";

interface TicketComponentProps {
  loggedIn: boolean;
}

const Ticket: React.FC<TicketComponentProps> = ({ loggedIn }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <TicketComponent loggedIn={loggedIn} />
      </IonContent>
    </IonPage>
  );
};

export default Ticket;
